.blog-page-top{
    height: 500px;
    
    .banner{ 
        
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       padding-top: 80px;
        h2{
            font-size: 64px;
            text-align: center;
           font-style: normal;
           font-weight: 600;
           line-height: 100%;
           background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
           background-clip: text;
           -webkit-background-clip: text;
           height: 100px;
           -webkit-text-fill-color: transparent;
       
           }
        }
}
.blog-page{
    
   
    .image-box{
        margin-top: 30px;
        margin-bottom: 30px;
        height: 350px;
        border-radius: 10px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .small-bar{
        background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
        width: 100%;
        max-width: 300px;
        height: 3px;
        margin: 40px auto 20px auto ;
        border-radius: 20px;
    }
    .content{
        margin: 30px auto;
        h3{
            background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
            background-clip: text;
            -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       text-align: center;
       font-size: 50px;
       margin: 20px auto;
        }
        p{
            color:#E9DEFF ;
            margin: 20px auto;
            span{
                background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
                background-clip: text;
                -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
           font-weight: 600;
           
            }
        }
        h4{
            background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
            background-clip: text;
            -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       font-weight: 600;
       font-size: 30px;
        }
        h5{
            background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
            background-clip: text;
            -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       font-weight: 500;
       font-size: 24px;
        }
    }
}
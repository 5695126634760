.heroSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 250px;
  padding-bottom: 174px;
  // border: 1px solid green;
  //height: 90vh;

  .topGlow {
    position: absolute;
    top: -140px;
    right: 0;
    z-index: 1;
    user-select: none;
  }
  .leftGlow {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
    user-select: none;
  }
  .grid {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    user-select: none;
    object-fit: cover;
    display: none;
  }
  // .topBox {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   gap: 38px;
  //   width: 52%;
  //   margin: 0 auto;
  //   color: white;
  //   margin-top: 200px;
  //   border-bottom: 1px solid rgba(155, 40, 168, 0) !important;

  //   h5 {
  //     font-family: Inter;
  //     font-size: 28px;
  //     font-style: normal;
  //     font-weight: 600;
  //     line-height: 100%; /* 28px */
  //     letter-spacing: -1.4px;
  //     background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
  //     background-clip: text;
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //   }
  //   p {
  //     text-align: center;
  //   }
  // }
  // .midBox {
  //   margin-top: 200px;
  //   width: 70%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   gap: 30px;
  //   flex-direction: column;
  //   //border: 1px solid red;
  //   padding: 20px;

  //   h1 {
  //     background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
  //     background-clip: text;
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     line-height: 100%; /* 100px */
  //     letter-spacing: -5px;
  //   }
  //   .human-aiDiv {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     transition: 0.3s ease-in-out;
  //     margin-top: 50px;
  //     margin-bottom: 40px;

  //     .leftBox {
  //       width: 35%;
  //       //  border: 1px solid green;
  //       h5 {
  //         font-family: Inter;
  //         font-size: 36px;
  //         font-style: normal;
  //         font-weight: 600;
  //         line-height: 100%; /* 36px */
  //         letter-spacing: -1.4px;
  //         background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
  //         background-clip: text;
  //         -webkit-background-clip: text;
  //         -webkit-text-fill-color: transparent;
  //       }
  //     }
  //     .rightBox {
  //       width: 70%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       gap: 15px;

  //       .iconRight {
  //       }
  //       .text-box-wrapper {
  //         position: relative;
  //         cursor: pointer;
  //         background: transparent;
  //         border-radius: 20px;
  //         .textBox {
  //           padding: 20px;
  //           font-size: 16px;
  //           font-family: Inter;
  //           line-height: 136%;
  //           letter-spacing: -4%;
  //           font-weight: 600;
  //           background-color: #000;
  //           border-radius: 8px;
  //         }
  //         &::before {
  //           content: "";
  //           background: #9208f3;
  //           // border: 4px solid #9208f3;
  //           position: absolute;
  //           opacity: 0.5;
  //           box-shadow: 0px 0px 25.3px 4px #930bed;
  //           border-radius: 8px;
  //           filter: blur(10.3px);
  //           z-index: -1;
  //           top: -3px;
  //           right: -3px;
  //           left: -3px;
  //           bottom: -3px;
  //         }
  //         &::after {
  //           content: "";
  //           position: absolute;
  //           top: -3px;
  //           left: -3px;
  //           right: -3px;
  //           bottom: -3px;
  //           border: 2px solid transparent;
  //           box-sizing: border-box;
  //           border-radius: 9px;
  //           animation: borderAnimation 4s linear infinite;
  //           @keyframes borderAnimation {
  //             0%,
  //             5%,
  //             10%,
  //             15%,
  //             20% {
  //               border-color: #9208f3 transparent transparent transparent;
  //             }
  //             // 5% {
  //             //   border-color: #9208f3 transparent transparent transparent;
  //             // }
  //             // 10% {
  //             //   border-color: #9208f3 transparent transparent transparent;
  //             // }
  //             // 15% {
  //             //   border-color: #9208f3 transparent transparent transparent;
  //             // }
  //             // 20% {
  //             //   border-color: #9208f3 transparent transparent transparent;
  //             // }
  //             25%,
  //             30%,
  //             35%,
  //             40%,
  //             45% {
  //               border-color: #9208f3 #9208f3 transparent transparent;
  //             }
  //             // 30% {
  //             //   border-color: #9208f3 #9208f3 transparent transparent;
  //             // }
  //             // 35% {
  //             //   border-color: #9208f3 #9208f3 transparent transparent;
  //             // }
  //             // 40% {
  //             //   border-color: #9208f3 #9208f3 transparent transparent;
  //             // }
  //             // 45% {
  //             //   border-color: #9208f3 #9208f3 transparent transparent;
  //             // }
  //             50%,
  //             55%,
  //             60%,
  //             65%,
  //             75% {
  //               border-color: #9208f3 #9208f3 #9208f3 transparent;
  //             }
  //             // 55% {
  //             //   border-color: #9208f3 #9208f3 #9208f3 transparent;
  //             // }
  //             // 60% {
  //             //   border-color: #9208f3 #9208f3 #9208f3 transparent;
  //             // }
  //             // 65% {
  //             //   border-color: #9208f3 #9208f3 #9208f3 transparent;
  //             // }

  //             // 70% {
  //             //   border-color: #9208f3 #9208f3 #9208f3 transparent;
  //             // }
  //             // 75% {
  //             //   border-color: transparent transparent #9208f3 #9208f3;
  //             // }
  //             80%,
  //             85%,
  //             90%,
  //             95%,
  //             100% {
  //               border-color: transparent transparent #9208f3 #9208f3;
  //             }
  //             // 85% {
  //             //   border-color: transparent transparent #9208f3 #9208f3;
  //             // }
  //             // 90% {
  //             //   border-color: transparent transparent #9208f3 #9208f3;
  //             // }
  //             // 95% {
  //             //   border-color: transparent transparent #9208f3 #9208f3;
  //             // }
  //             // 100% {
  //             //   border-color: #9208f3 transparent transparent transparent;
  //             // }
  //           }
  //         }
  //       }
  //     }
  //     p {
  //       text-align: start !important;
  //     }
  //     a {
  //       margin-top: 200px;

  //       .scroll {
  //         width: 100%;
  //         margin-bottom: 20px;
  //         color: white;
  //         display: flex;
  //         align-items: center;
  //         cursor: pointer;
  //         justify-content: center;
  //         // border: 1px solid red;
  //         img {
  //           margin-top: 100px !important;
  //         }
  //       }
  //     }
  //   }
  // }
  .midBox {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    // border: 1px solid red;
    // padding: 20px;
    .leftArea {
      width: 36%;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      gap: 41px;
      margin-top: 60px;
      // border: 1px solid red;

      h2 {
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 48px */
        background: linear-gradient(90deg, #9b28a8 0%, #9208f3 100%);
        background-clip: text;
        -webkit-background-clip: text;
        height: 100px;
        -webkit-text-fill-color: transparent;

        // .ant-carousel .slick-slide {
        //   text-align: center;
        //   height: 160px;
        //   line-height: 160px;
        //   width: 200px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   overflow: hidden;
        //   h2 {
        //     margin: 0;
        //   }
        // }
      }
      p {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%; /* 24.48px */
      }
      .btnDiv {
        .joinList {
          display: flex;
          width: 163px;
          height: 43px;
          //  padding: 16.033px 52.109px;
          justify-content: center;
          align-items: center;
          gap: 10.021px;
          border-radius: 52px;
          background: linear-gradient(106deg, #9b28a8 10.92%, #9208f3 88.71%);
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
        .watchDemo {
          display: flex;
          width: 168px;
          height: 44px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 52px;
          //  border: 1px solid #e9deff;
          background: #0e0026;
          box-shadow: 0px 0px 19px 9px rgba(162, 45, 247, 0.1),
            0px 0px 46.852px 0px rgba(162, 45, 247, 0.1),
            0px 0px 25.481px 0px rgba(162, 45, 247, 0.1),
            0px 0px 13px 0px rgba(162, 45, 247, 0.1),
            0px 0px 6.519px 0px rgba(162, 45, 247, 0.1),
            0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
          color: #e9deff;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.16px;
          text-transform: uppercase;
          position: relative;

          &::after {
            background: linear-gradient(90deg, #9208f3 80%, #9b28a8 20%);
            border-radius: 25px;
            bottom: -1px;
            content: "";
            left: -1px;
            position: absolute;
            right: -1px;
            top: -1px;
            z-index: -1;
          }
        }
      }
    }
    .rightArea {
      width: 60%;
      z-index: 999;
      position: relative;
    }
  }
}
@media screen and (max-width: 1870px) {
  .heroSection {
    // height: 70vh;
    .midBox {
      .leftArea {
        h2 {
          font-size: 42px;
        }
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .heroSection {
    // height: 60vh;
    .midBox {
      .leftArea {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .heroSection {
    padding-top: 100px;
    padding-bottom: 10px;
    .midBox {
      width: 90%;
      // margin-top: 160px;
      .leftArea {
        h2 {
          font-size: 34px;
          height: 66px;
        }
      }
      .rightArea{
        width: 50%;
        .rightVideo{
          width: 100%;
          height: 400px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .heroSection .midBox .leftArea h2 {
    // font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .heroSection {
    // height: auto;
    padding-top: 0;
    .topGlow {
      display: none;
    }
    .leftGlow {
      display: none;
    }
    .midBox {
      margin-top: 50px;
      flex-direction: column;
      width: 95%;
      .leftArea {
        width: 100%;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        h2 {
          font-size: 30px;
          padding-bottom: 10px;
          height: auto;

          .sliderDivv {
            width: 100vw !important;
          }
        }
        p {
          text-align: center;
          font-size: 15px;
        }
        .btnDiv {
          flex-direction: column;
          .joinList {
            font-size: 15px;
          }
          .watchDemo {
            font-size: 15px;
          }
        }
      }
      .rightArea {
        width: 100% !important;
        .rightVideo{
          width: 100% !important;
        }
      }
    }
    .sliderDivv {
      overflow: hidden;
    }
  }
}

// @media screen and (max-width: 1825px) {
//   .heroSection .midBox {
//     width: 80%;
//     //  border: 4px solid blue;
//   }
// }
// @media screen and (max-width: 1660px) {
//   .heroSection {
//     .midBox {
//     }
//   }
// }

// @media screen and (max-width: 1610px) {
//   .heroSection .midBox .human-aiDiv .leftBox h5 {
//     font-size: 30px;
//   }
// }

// @media screen and (max-width: 1440px) {
//   .heroSection {
//     .midBox {
//       margin-top: 120px;
//       width: 70%;
//       gap: 20px;

//       h1 {
//         font-size: 60px;
//         letter-spacing: -2px;
//       }
//       .human-aiDiv {
//         // border: 1px solid red;
//         margin-top: 10px;
//         margin-bottom: 0;
//         .leftBox {
//           display: flex;
//           justify-content: end;
//           padding-right: 5px;
//           h5 {
//             font-size: 24px;
//           }
//         }
//         .rightBox {
//           .text-box-wrapper {
//             .textBox {
//               padding: 8px;
//               font-size: 14px;
//               font-weight: normal;
//               &::before {
//                 //filter: blur(20px);
//                 box-shadow: 1px 1px 1px 1px #930bed;
//               }
//             }
//           }
//         }
//       }
//       p {
//         font-size: 13px;
//         font-weight: normal;
//       }
//       a {
//         margin-top: 44px;
//         .scroll {
//           img {
//             width: 60px;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 1360px) {
//   .heroSection {
//     .midBox {
//       width: 70%;
//       // .human-aiDiv {
//       //   .rightBox {
//       //     width: 50%;
//       //   }
//       //   .leftBox {
//       //     width: 50%;
//       //     display: flex;
//       //     align-items: center;
//       //     justify-content: center;
//       //     h5 {
//       //     }
//       //   }
//       // }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .heroSection {
//     .midBox {
//       h1 {
//         font-size: 50px;
//       }
//       .human-aiDiv {
//         .leftBox {
//           h5 {
//             font-size: 18px;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 600px) {
//   .heroSection {
//     padding: 20px;
//     .topGlow {
//       display: none;
//     }
//     .leftGlow {
//       display: none;
//     }

//     .midBox {
//       width: 100%;
//       margin-top: 50px;
//       padding: 0;

//       h1 {
//         font-size: 38px;
//         margin-bottom: 0;
//       }
//       p {
//         text-align: center;
//       }
//       .human-aiDiv {
//         flex-direction: column;
//         gap: 20px;
//         margin-top: 0px;
//         margin-bottom: 10px;
//         .leftBox {
//           width: 100%;
//           justify-content: center;
//           h5 {
//             font-size: 18px;
//           }
//         }
//         .rightBox {
//           width: 100%;
//           .iconRight {
//             display: none;
//           }
//         }
//       }
//       a {
//         margin-top: 30px !important;
//         .scroll {
//           margin-top: 0;
//           margin-bottom: 50px;
//         }
//       }
//     }
//   }
// }

.desktop-nav {
  background: rgba(5, 5, 5, 0.1);
  backdrop-filter: blur(67px);
  padding: 38px 20px;
  position: fixed;
  width: 100%;
  z-index: 4;
  position: fixed;
  //border: 2px solid rgb(197, 126, 126);

  .itemsWrapper {
    max-width: 72%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //  border: 1px solid red;

    .navLogo {
      img {
      }
    }

    .nav-items {
      color: #E9DEFF;
      width: 60%;
      margin: 0 auto;
      //border: 2px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 1px solid rgb(43, 173, 182);

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 70px;
        margin-bottom: 0;
        &:hover {
          // .resourceList {
          //   background-color: #3f3546 !important;
          //   padding: 6px;
          //  // padding-right: 10px;
          //   transition: 0.7s ease-in-out;
          //   .drop-downss{

          //   }
          // }
          li {
            //  background-color: none;
          }
        }
        li {
          cursor: pointer;
          position: relative;
          font-style: normal;
          font-weight: 500;

          line-height: normal;
          text-decoration: none !important;


          // .sidebar-menu {
          //   list-style: none;
          //   border: 1px solid red;
          //   padding: 0;
          //   margin: 0;
        
          //   .sidebar-item {
          //     padding: 10px;
          //     border: 1px solid red;
          //     cursor: pointer;
          //     position: relative;
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
        
          //     &:hover {
          //       background-color: #e9ecef; // Adjust hover color as needed
          //     }
        
          //     .dropdown-icon {
          //       margin-left: auto;
          //       font-size: 12px;
                
          //     }
        
          //     .dropdown-menu {
          //       position: relative;
          //       top: 10px;
          //       left: 0;
          //      display: flex;
          //      align-items: center;
          //      justify-content: center;
          //      flex-direction: column;
          //       background-color: white;
          //       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          //       list-style: none;
          //       padding: 0;
          //       margin: 0;
          //       width: 100%;
          //       z-index: 1000;
        
          //       li {
          //         padding: 10px 20px;
          //         cursor: pointer;
        
          //         &:hover {
          //           background-color: #f0f0f0; // Adjust hover color as needed
          //         }
          //       }
          //     }
          //   }
          // }

          &:hover {
            // border: 1px solid pink;

            .drop-downss {
              visibility: visible;
              opacity: 1;
              padding: 0;
            }
            .resourceDropDown {
              transform: rotate(180deg);
              transition: 0.3s ease-in-out;
            }
          }
          .resourceDropDown {
            width: 100%;
            height: 100%;
            transition: 0.3s ease-in-out;
          }
          .drop-downss {
            width: 125px;
            height: auto;
            padding: 20px 0px 20px 15px;
            display: flex;
            align-items: flex-start;
            gap: 5px;
            flex-direction: column;
            color: #E9DEFF;
            font-size: 13px;
            background-color: #12001a;
            // border-radius: 20px;
            border: 1px solid gray;
            position: absolute;
            top: 26px;
            left: -15px;
            visibility: hidden;
            opacity: 0;
            // transition: 0.7s ease-in-out;

            li {
             // margin-left: 0px;
              margin-bottom: 0px;
             
              padding: 10px;
              height: auto;
              &:hover {
                background-color: #1E1E1E;
                width: 100%;
             
                .drop-downss{
                  padding: 0px !important;
                }
              }
            }

            // .nav-links {
            //   width: 100%;
            // }

            hr {
              width: 100%;
              height: 2px;
              background-color: white !important;
            }
          }

          a {
            color: #e9deff !important;
            text-decoration: none;
            position: relative;

            &::after {
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 3px;
              bottom: -10px;
              left: 0;
              background-color: #9208f3;
              transition: 0.5s ease-in-out;
              content: "";
            }
            &.active {
              position: relative;
              &::after {
                opacity: 1;
                position: absolute;
                width: 100%;
                height: 3px;
                bottom: -10px;
                left: 0;
                background-color: #9208f3;
                transition: 0.5s ease-in-out;
                content: "";
              }
            }

            &:hover {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
      .resDropDown{
        
      }
    }
    .navvSignIn {
      text-decoration: none;

      .nav-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        //border: 1px solid blue;
        button {
          color: #E9DEFF;
          display: flex;
          position: relative;
          width: 99px;
          height: 37px;
          align-items: center;
          justify-content: center;
          padding: 10px;
          font-weight: 500 !important;
          gap: 5px;
          border-radius: 19.169px;
          border: none;
          background: #0e0026;
          box-shadow: 0px 0px 16px 9px rgba(162, 45, 247, 0.1),
            0px 0px 40.852px 0px rgba(162, 45, 247, 0.1),
            0px 0px 20.481px 0px rgba(162, 45, 247, 0.1),
            0px 0px 10px 0px rgba(162, 45, 247, 0.1),
            0px 0px 3.519px 0px rgba(162, 45, 247, 0.1),
            0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);

          &::after {
            background: linear-gradient(90deg, #9208f3 80%, #9b28a8 20%);
            border-radius: 25px;
            bottom: -1px;
            content: "";
            left: -1px;
            position: absolute;
            right: -1px;
            top: -1px;
            z-index: -1;
          }

          .btnIcon {
            color: #e9deff;
            object-fit: contain;
            
            // transform: rotate(-40deg);
          }
        }
        .waitBtn {
          width: 140px;
          height: 37px;
          font-weight: 500;
          color: #E9DEFF;
          box-shadow: 0px 0px 16px 9px rgba(162, 45, 247, 0.1),
          0px 0px 30.852px 0px rgba(162, 45, 247, 0.1),
          0px 0px 10.481px 0px rgba(162, 45, 247, 0.1),
          0px 0px 10px 0px rgba(162, 45, 247, 0.1),
          0px 0px 3.519px 0px rgba(162, 45, 247, 0.1),
          0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
        }
      }
    }
  }
}
.resourceList {
  position: relative;
  &:hover {
    // background-color: #3f3546 !important;
    //padding: 6px;
    // transition: 0.7s ease-in-out;
  }
}
@media screen and (max-width: 2500px) {
  .mobile-navbar {
    display: none;
  }
}
@media screen and (max-width: 1770px) {
  .desktop-nav .itemsWrapper .nav-items ul {
    font-size: 12px;
  }
}
@media screen and (max-width: 1630px) {
  .desktop-nav .itemsWrapper {
    max-width: 97%;
  }
}
@media screen and (max-width: 1440px) {
  .desktop-nav {
    padding: 15px 10px;
    .itemsWrapper {
      width: 90%;
      .navLogo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 105px;
          margin-top: 5px;
        }
      }
      .nav-items {
        width: 55%;
        ul {
          gap: 45px;
        }
      }
      .nav-btns {
        button {
          font-size: 13px;
        }
      }
    }
  }

  .desktop-nav .itemsWrapper .nav-items ul:hover .resourceList {
    // background-color: #3f3546 !important;
    // padding: 6px;
    // padding-right: 15px;
    // transition: 0.7s ease-in-out;
  }
}
@media screen and (max-width: 1230px) {
  .desktop-nav {
    .itemsWrapper {
      .nav-btns {
        button {
          font-size: 12px !important;
          width: 87px;
          height: 31px !important;
          .waitBtn {
            width: 108px !important;
            font-size: 12px !important;
            height: 31px;
          }
        }
      }
      .nav-items {
        width: 66%;
        ul {
          gap: 40px;
        }
      }
    }
  }
}
// @media screen and (max-width: 1160px) {
//   .desktop-nav {
//     .itemsWrapper {
//       .navLogo {
//         img {
//           width: 100px;
//         }
//       }
//       .nav-items {
//         width: 100%;
//         ul {
//           gap: 25px;
//           font-size: 12px;
//           justify-content: space-evenly;
//           display: flex;
//           width: 100%;
//           li {
//             a {
//               text-decoration: none;
//               color: white;
//             }
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 1100px) {
  .desktop-nav {
    .itemsWrapper {
      .nav-items {
        ul {
          gap: 20px;
        }
      }
      .navvSignIn {
        .nav-btns {
          .navSignIn {
            width: 90px;
          }
          .waitBtn {
            width: 112px !important;
            height: 37px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .desktop-nav {
    display: none;
  }

  .mobile-navbar {
    width: 100%;
    height: 68px;
    background: #9208f3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    // border: 10px solid red;
    .mobileLogo {
      // width: 80px;
      // height: 45px;
      //object-fit: cover;
    }

    .menu-btn {
      background: transparent;
      border: none;
      width: 56px;
      height: 39px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      &:focus {
        box-shadow: none;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .offcanvas {
    width: 100% !important;
    position: absolute;
    top: 0px;
    left: 0;
  }
  .offcanvas-header {
    height: 68px;
    background: #9208f3;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  .offcanvas-body {
    background: #9208f3;
    color: var(--main-color);
    width: 100% !important;
  }
  .Offcanvas.Title {
    img {
      width: 30px;
      height: 30px;
    }
  }
  .blue-button {
    border: 2.3px solid #fff !important;

    &:hover {
      background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
      border: none !important;
    }
  }
  .logostyle {
    width: 120px;
    height: 30px;
    margin-top: 10px;
  }
  .mobileScreenUl {
    padding-top: 30px;

    &:hover {
      .resourceList {
        //  background-color: #3f3546 !important;
        // padding: 6px;
        // padding-right: 10px;
        // transition: 0.7s ease-in-out;
        .drop-downss {
        }
      }
    }
    .resourceContentArrow{
      transform: rotate(-90deg);
    }
    
    li {
    //  margin-bottom: 10px;
      padding-left: 20px;
      color: white;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
      list-style: none;
      text-decoration: none;


      &:hover {
        //  border: 1px solid pink;
        .drop-downss {
          visibility: visible;
          opacity: 1;
        }
        .resourceDropDown {
          transform: rotate(180deg);
          transition: 0.7s ease-in-out;
        }
      }

      .resourceDropDown {
        // width: 100%;
        // height: 100%;
        transition: 0.7s ease-in-out;
        margin-top: -3px;
      }
      .drop-downss {
        width: 135px;
        height: auto;
        padding: 20px 0px 20px 15px;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        flex-direction: column;
        color: white;
        font-size: 13px;
        background-color: #72518a;
        // border-radius: 20px;
        border: 1px solid gray;
        position: absolute;
        bottom: -240px;
        left: 0;
        // margin-top: 100px;
        // top: 300px;
        // transform: translate(-50%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: 0.7s ease-in-out;
      }
      a {
        color: WHITE;
        text-decoration: none;
      }
    }
  }

  .loginBtn {
    //  margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 150px;
    //height: 37px;
    padding: 10px 14px;

    border-radius: 19.169px;
    border: 1px solid #e9deff;
    background: none;
    box-shadow: 0px 0px 19px 9px rgba(162, 45, 247, 0.1),
      0px 0px 46.852px 0px rgba(162, 45, 247, 0.1),
      0px 0px 25.481px 0px rgba(162, 45, 247, 0.1),
      0px 0px 13px 0px rgba(162, 45, 247, 0.1),
      0px 0px 6.519px 0px rgba(162, 45, 247, 0.1),
      0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
    border: 1px solid white;
    color: white;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;

    text-transform: uppercase;
  }
  .resourceList {
    &:hover {
      background-color: transparent !important;
      padding: 0px;
      transition: 0.7s ease-in-out;
    }
  }
}

.offcanvas-header .btn-close {
  display: none;
}
.arrow-upp{
//background: #12001a;
//transform: rotate(180deg);
transition: 0.7s ease-in-out;
&:hover{
  cursor: pointer;
  transition: 0.7s ease-in-out;
}
}
// .resourceContentArrow{
//   transform: rotate(-90deg);
// }

.interact-main {
  position: relative;
  // border: 2px solid green;
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: -115px;
    background-image: url("../../assets/images/home/grid.png");
    right: 0;
    display: none;
  }

  .interGlow {
    position: absolute;
    top: -200px;
    right: 0;
    z-index: -1;
  }
  .interactionWrapper {
    margin-top: 66px !important;
    max-width: 70%;
    margin: 0 auto;
    //border: 1px solid red;
    padding-top: 0px;
    height: 75vh;
    position: relative;

    .imWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 565px;
      margin: 0 auto;
      position: relative;
      height: 390px;
      //border: 1px solid forestgreen;
      margin-top: 100px;
      transition: 1s 0.5s ease-in-out;
      // animation: zoom-in-zoom-out  4s 1s ease-in-out reverse both;

      // @keyframes zoom-in-zoom-out {
      //   0% {
      //     transform: scale(1, 1);
      //   }
      //   50% {
      //     transform: scale(1.4, 1.4);
      //   }
      //   100% {
      //     transform: scale(1, 1);
      //   }
      // }
      &:hover {
        transform: scale(1.4);
        .playButton{
          .iconPlay{
            // color: black;
          }
        }
      }

      .playButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgb(145, 142, 142);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
      
        .iconPlay{
          color: white;
          cursor: pointer;
          // width: 60px;
          // height: 60px;
          object-fit: cover;
        }
      }
.videoWrapper{
  // .youtubeSlaraVideo{
  //   border-radius: 20px;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
}
      video {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
       
      }
    }
  }
}

@media screen and (max-width: 1860px) {
  .interact-main {
    .interactionWrapper {
      padding: 20px;
      max-width: 100%;
      h2 {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .interact-main {
    .interactionWrapper {
      margin-top: 20px !important;
      height: auto;
      h2 {
        font-size: 45px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .interact-main {
    .interactionWrapper {
      margin-top: 20px !important;
      height: auto;
      h2 {
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .interact-main {
    .interactionWrapper {
      margin-top: 0px !important;
      height: auto;
      max-width: 100%;
      h2 {
        font-size: 30px;
      }
      .imWrapper {
        width: 100%;
        margin: 0 auto;
        height: auto;
        margin-top: 20px;
        &:hover {
          transform: none;
        }
        .playButton{
          width: 80px;
          height: 80px;
          .iconPlay{
            width: 40px;
          }
        }

        .videoWrapper{
          width: 100%;
          height: 300px;
          .youtubeSlaraVideo{
           
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

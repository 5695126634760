.persona-created-main {
  margin: 55px 20px 30px 0px;
  border-radius: 40px;
  position: relative;
  max-width: 1092px;
  min-height: 735px;
  width: 100%;
  height: 100%;
  padding: 37px 44px;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 735px;
    background-image: url(../../../../../public/assets/dashboardimages/images/bg-border.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
  .persona-created-top {
    button {
      width: 219px;
    }
  }
  .persona-card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
    column-gap: 40px;
  }
  .persona-created-bottom {
    justify-content: flex-end;
    button {
      width: 165px;
      height: 49px;
    }
  }
}
.persona-card {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  max-width: 180px;
  width: 100%;
  padding: 14px 10px;
  .card-top {
    img {
      width: 43px;
      height: 47px;
      border-radius: 5px;
      border: 1px solid #ffffff;
    }
  }
  h6 {
    margin-top: 10px;
  }
  .card-bottom {
    margin-top: 16px;
    padding: 16px 0px 5px 0px;
    border-top: 1px dashed var(--Primary-1, #9b28a8);
  }
}

@media only screen and (max-width: 1200px) {
  .persona-created-main {
  margin: 55px 0px 30px 0px;

    .persona-created-top {
      .persona-card-wrapper {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        .persona-card {
          
          .card-bottom {
            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .persona-created-main {
    .persona-created-top {
      .persona-card-wrapper {
        gap: 10px;
        .persona-card {
          .card-bottom {
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .persona-created-main {
    padding: 20px !important;
    gap: 20px !important;
    .persona-created-top {
      h5 {
        text-align: center;
      }
      .persona-card-wrapper {
        gap: 10px;
        display: grid !important;
        grid-template-columns: repeat(1, 1fr) !important;
        .persona-card {
          width: 100% !important;
          max-width: 100%;
          .discussion-card-p{
            text-wrap: wrap!important;
          }
          .card-bottom {
            span {
              font-size: 10px;
            }
          }
        }
      }
      button {
        font-size: 16px;
        margin: auto;
        width: 165px;
        height: 49px;
      }
    }
    .persona-created-bottom {
      justify-content: center;
      button {
        width: 165px;
        height: 49px;
      }
    }
  }
}

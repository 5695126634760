.testimonial {
  margin-top: 186px !important;
  margin-bottom: 150px !important;
  color: white;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  //background: cadetblue;
  // border: 2px solid rgb(186, 224, 13);
  overflow: hidden;

  .testiGlow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
    user-select: none;
  }

  .cardsContainer {
    // width: 100%;
    // background: red;
    // align-items: center;
  }

  .arrow-slider-btns {
    margin-top: 40px !important;
    //  border: 1px solid green;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 55px !important;

    .testi-prev-btn {
      position: relative;
      width: 62.505px;
      height: 62.505px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding: 18px;
      border-radius: 50px;
      outline: none;
    }
    .testi-next-btn {
      position: relative;
      width: 62.505px;
      height: 62.505px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding: 18px;
      border-radius: 50px;
      transform: rotate(180deg);
      outline: none;
    }
  }
  h2 {
    margin-top: 56px;
    // margin-left: 300px;
    margin-bottom: 120px;
  }
  .cardsContainer {
    // margin-top: 56px;
    // padding-left: 420px;
  }
}
.slick-track {
  display: flex;
}

.slick-arrow.slick-prev {
  position: absolute;
  visibility: hidden;
  border-radius: 50%;
  background: white;
  width: 62.505px;
  height: 62.505px;
  border-radius: 50px;
  bottom: 200px;
  border: none;
  color: transparent;
  left: 580px;
  font-size: 20px !important;

  &::before {
    content: url("../../assets/images/home/leftAr.png");
    position: absolute;
    top: 13px;
    left: 11px;
    opacity: 1;
  }
}
.slick-arrow.slick-prev.slick-disabled {
  display: none;
}

.slick-arrow.slick-next.slick-disabled {
  display: none;
}
.slick-arrow.slick-next {
  position: absolute;
  visibility: hidden;
  border-radius: 50%;
  background: white;
  width: 62.505px;
  height: 62.505px;
  border-radius: 50px;
  border: none;
  bottom: 200px;
  color: transparent;
  right: 550px;
  font-size: 20px !important;

  &::before {
    content: url("../../assets/images/home/leftAr.png");
    position: absolute;
    top: 5px;
    left: 15px;
    opacity: 1;
    transform: rotate(180deg);
  }
}
@media screen and (max-width: 1890px) {
  .testimonial .cardsContainer {
    margin-top: 56px;
  }
}

@media screen and (max-width: 1690px) {
  .testimonial {
    .testiGlow {
      top: 50%;
      left: 50%;
      transform: translate(-60%, -50%);
    }
  }
}

@media screen and (max-width: 1440px) {
  .testimonial {
    // max-width: 80%;
    margin-top: 80px !important;
    margin-bottom: 70px !important;

    h2 {
      //  margin-left: 145px;
      font-size: 45px;
    }
    .arrow-slider-btns {
      width: 20%;
      .testi-next-btn {
        width: 50px;
        height: 50px;
      }
      .testi-prev-btn {
        width: 50px;
        height: 50px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .testimonial {
    h2 {
      font-size: 40px;
    }
    .cardsContainer {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 1130px) {
  .testimonial .cardsContainer .arrow-slider-btns {
    width: 23%;
  }
}
@media screen and (max-width: 600px) {
  .testimonial {
    max-width: 100%;
    padding: 20px;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
    .testiGlow {
      display: none;
    }
    h2 {
      margin-top: 8px;
      margin-left: 0;
      font-size: 30px;
      margin-bottom: 30px;
    }
    .slick-arrow.slick-next {
      display: none;
    }
    .slick-arrow.slick-prev {
      display: none;
    }
    .slick-disabled {
      display: none;
    }
    .arrow-slider-btns {
      width: 100%;
      justify-content: center;
      gap: 20px;
      // margin-top: -50px !important;

      .testi-next-btn {
        width: 45px;
        height: 45px;
        padding-left: 13px;
        padding-top: 15px;
      }
      .testi-prev-btn {
        width: 45px;
        height: 45px;
        padding-left: 17px;
        padding-top: 18px;
      }
    }
    .cardsContainer {
      margin-top: 19px;
    }
  }
  .slick-track {
    display: flex;
    //gap: 50px;
  }
}
@media screen and (max-width: 400px) {
  .testimonial {
    // margin-top: 0 !important;
  }
}

.joinWaitlist {
  margin: 0 auto;
  margin-bottom: 160px;
  position: relative;
 // border: 1px solid red;

  h2 {
    letter-spacing: -0.64px;
  }

  .mainDiv {
    display: flex;
    margin-top: 120px !important;
     width: 1191px;
    // border: 1px solid green;
     height: 474px;
    margin: 0 auto;
    padding: 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    background-image: url("../../assets/images/home/joinWaitlistBg.webp");
   // background-position: center;
    background-repeat: no-repeat;
  //  background-size: cover;
    // background: rgba(255, 255, 255, 0.13);
    // background: linear-gradient(
    //   111deg,
    //   rgba(206, 46, 153, 0.17) -20.4%,
    //   rgba(97, 45, 163, 0.5) 19.63%,
    //   rgba(70, 41, 150, 0.5) 45.82%,
    //   rgba(81, 40, 149, 0.5) 73.48%,
    //   rgba(206, 46, 153, 0.5) 119.32%
    // );
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    // &::before {
    //   position: absolute;
    //   content: "";
    //  // background-image: url("../../assets/images/home/waitlistBorder.png");
    //   background-repeat: no-repeat;
      
    //   background-size: cover;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1;
    //   width: 100%;
    //   height: 100%;
    // }

    .formData {
      // width: 781px;
      max-width: 780px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      position: relative;
      z-index: 2;
      form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        .nameDivContainer {
          gap: 20px;
        }

        .textArea {
          // width: 781px;
          width: 100%;
          height: 140px;
          border-radius: 10px;
          background: #f9f9f9;
          padding: 20px;
          margin-bottom: 30px;
          border: none;
          outline: none;
        }
        button {
          display: flex;
          width: 100%;
          padding: 16px 32px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          border: 1px solid var(--Primary-1, #9b28a8);
          background: linear-gradient(90deg, #9208f3 6.5%, #9b28a8 94.5%);

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
.flname {
  display: none !important;
}

@media screen and (max-width: 1440px) {
 
  .joinWaitlist{

    h2{
      font-size: 45px;
    }
   
  }
}
@media screen and (max-width:1250px) {
  .joinWaitlist{
    h2{
      font-size: 40px;
    }
    .mainDiv{
      width: 900px;
      // &::before{
      //   background-size: 100% 101%;
      // }
    }
  }
  
}
@media screen and (max-width: 600px) {
  .joinWaitlist {
    margin-top: 50px;
    // max-width: 100%;
    padding: 0px;
    margin-bottom: 45px;
    h2{
      font-size: 30px;
    }
    .mainDiv {
      width: auto;
      margin-top: 29px !important;
      padding: 20px;
      background-image: none;
         background: linear-gradient(
      111deg,
      rgba(206, 46, 153, 0.17) -20.4%,
      rgba(97, 45, 163, 0.5) 19.63%,
      rgba(70, 41, 150, 0.5) 45.82%,
      rgba(81, 40, 149, 0.5) 73.48%,
      rgba(206, 46, 153, 0.5) 119.32%
    );
      // &::before{
      //   display: none;
      // }
    }
  }
}

.privacyPolicyContainer{
    padding-top: 150px;
    .container{
        h2{
            font-size: 50px;
          text-align: center;
        }
        .para{
            max-width: 900px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }
        .privacyContent{
            ol{
                list-style: decimal;
            
                li{
                    
                }
                    ul{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        list-style: disc;
                        padding-left: 50px;
                        font-size: 18px;
                        li{
        
                        }
                    }
                
            }
        }
    }
   
   
}

@media screen and (max-width:600px) {
    .privacyPolicyContainer{
        padding-top: 50px;
        .container{
h2{
    font-size: 35px;
}
            .privacyContent{
                ol{
                    li{

                    }
                    ul{
                        padding-left: 0px;
                        gap: 0px;
                    }
                }
            }
        }
    }
    
}
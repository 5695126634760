// .slaraCards {
//   width: 426.757px;
//   max-height: 148.04px;
//   padding: 16px 34px 20px 10px !important;
//   // border: 1px solid red;
//   border-radius: 10px;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // background: linear-gradient(94deg, #9208f3 3.62%, #9b28a8 101.16%);

//   gap: 37px;
//   &:hover {
//     animation: pinkLine 1s alternate-reverse both;
//     z-index: 1;
//     transition: 0.5s ease-in-out;
//   }

//   // @keyframes pinkLine {
//   //   0% {
//   //     width: 400px;
//   //     // left: 40px;
//   //   }
//   //   100% {
//   //     width: 350px;
//   //   }
//   // }
//   .cardBack {
//     position: absolute;
//     width: 426.757px;
//     max-height: 248.04px;
//     height: 100%;
//     background-image: linear-gradient(94deg, #9208f3 3.62%, #9b28a8 101.16%);
//     opacity: 0.5;
//     top: 0;
//     right: 0;
//     z-index: -1;
//     border-radius: 10px;
//     transition: all 1s ease-in-out;

//     &:hover {
//        width: 300.757px;
//       right: 0;
//      // border-radius: 50px 0px 0px 50px;
//       transition:all 1.5s ease-in-out;
//       .cardAnimDiv{
//         z-index: 99;
//         opacity: 1;
//       }
//     }

//     .cardAnimDiv{
//       position: absolute;
//       top: 0;
//       right: 0;
//       z-index: 2;
//     width: 342px;
//     height: 100%;
//     }
//   }

//   .leftArea {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 30%;
//     // border: 1px solid blue;
//     height: 100%;
//   }

//   .rightArea {
//     height: 100%;
//     display: flex;
//     align-items: left;
//     justify-content: center;
//     flex-direction: column;
//     gap: 10px;
//      //border: 1px solid green;
//     .slaraTitle {
//       width: 180px;
//       letter-spacing: -0.2px;
//       text-align: left;
//       //border: 1px solid red;
//       margin-bottom: 0;
//     }
//     .slarades {
//       line-height: 160%;
//       margin-bottom: 0;
//     }
//   }
// }

.slaraCards {
  // width: 436.951px;
  // height: 350px;
border-radius: 10px;
  padding: 30px 30px 30px 30px;
  position: relative;
  display: flex;
  align-items: left;
  justify-content: start;
  flex-direction: column;
  cursor: pointer;
  
  gap: 20px;
  .cardBack {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(94deg, #9208f3 3.62%, #9b28a8 101.16%);
    opacity: 0.5;
    top: 0;
    right: 0;
    z-index: -1;
    
  }
  h2 {
   // min-height: 80px;
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  p {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
}
// for slara card buttons 
.btnDiv {
  margin-top: auto;
  .joinList {
    display: flex;
    width: 163px;
    height: 43px;
    //  padding: 16.033px 52.109px;
    justify-content: center;
    align-items: center;
    gap: 10.021px;
    border-radius: 52px;
    background: linear-gradient(106deg, #9b28a8 10.92%, #9208f3 88.71%);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .watchDemo {
    display: flex;
    width: 168px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 52px;
    //  border: 1px solid #e9deff;
    background: #0e0026;
    box-shadow: 0px 0px 19px 9px rgba(162, 45, 247, 0.1),
      0px 0px 46.852px 0px rgba(162, 45, 247, 0.1),
      0px 0px 25.481px 0px rgba(162, 45, 247, 0.1),
      0px 0px 13px 0px rgba(162, 45, 247, 0.1),
      0px 0px 6.519px 0px rgba(162, 45, 247, 0.1),
      0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
    color: #e9deff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    position: relative;

    &::after {
      background: linear-gradient(90deg, #9208f3 80%, #9b28a8 20%);
      border-radius: 25px;
      bottom: -1px;
      content: "";
      left: -1px;
      position: absolute;
      right: -1px;
      top: -1px;
      z-index: -1;
    }
  }
}
@media screen and (max-width: 1440px) {
  .slaraCards {
   padding: 20px;
   height: auto;
   gap: 20px;
    h2 {
      line-height: normal;
      font-size: 25px;
        min-height: 60px;
    // border: 1px solid blue;
    }
    p{
    font-size: 15px;
    max-height: 400px;
    height: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .slaraCards{
    h2{
      min-height: auto;
      font-size: 22px;
    }
    p{
      text-align: center;
    }
  }
}
// @media screen and (max-width: 1440px) {
//   .slaraCards {
//     width: 360px;
//     gap: 20px;
//     padding: 10px 10px 20px 10px !important;
//     height: 450px;
//     .cardBack {
//       width: 360px;
//       &:hover {
//         width: 240px;
//       }
//       .cardAnimDiv {
//         width: 290px;
//       }
//     }
//     .leftArea {
//       width: 25%;
//       //  border: 1px solid green;
//       img {
//         width: 60px;
//         height: 60px;
//       }
//     }
//     .rightArea {
//       .slaraTitle {
//         font-size: 16px !important;
//         font-weight: 400 !important;
//       }
//       .slarades {
//         font-size: 12px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1340px) {
//   .slaraCards {
//     width: 300px;

//     .rightArea {
//       width: 67%;
//       .slaraTitle {
//       }
//     }
//     .cardBack {
//       width: 300px;
//       &:hover {
//         width: 200px;
//       }
//       .cardAnimDiv {
//         width: 230px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .slaraCards {
//     width: 100%;
//     gap: 10px;
//     justify-content: center;
//     padding: 10px;
//     .rightArea {
//       width: 55%;
//       .slaraTitle {
//         width: 100%;
//       }
//     }
//     .leftArea {
//       width: 30%;
//     }
//   }
// }
// @media screen and (max-width: 600px) {
//   .slaraCards {
//     width: 100%;
//     .leftArea {
//       width: 30%;
//       img {
//         width: 80px;
//         height: 80px;
//       }
//     }
//     .rightArea {
//       width: 65%;
//     }
//     .cardBack {
//       width: 100%;
//       .cardAnimDiv {
//         display: none;
//       }
//     }
//   }
// }

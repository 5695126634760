.signInwrapper {
  padding: 20px 20px 0px;
  // border: 2px solid green;
  background-image: url("../../assets/images/signIn/signInBg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  //padding-bottom: 200px;
  height: 100%;
  width: 100%;
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav {
    max-width: 70%;
    margin: 0 auto;
    // margin-top: 30px !important;
    position: absolute;
    top: 30px;
    left: 15%;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .logo {
      img {
        width: 100%;
        height: 100%;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 181.333px;
      height: 64px;
      border-radius: 13.333px;
      border: 2px solid #fff;
      font-family: Inter;
      font-size: 17.067px;
      font-style: normal;
      font-weight: 700;
      line-height: 30.04px;
      letter-spacing: 0.933px;
      background: none;
      color: white;
      transition: 0.3s ease-in-out;

      &:hover {
        background: linear-gradient(106deg, #9208f3 10.92%, #9b28a8 88.71%);
        border: none;
        transition: 0.3s ease-in-out;
      }
    }
  }
  .centerDiv {
    margin: 0 auto;
    margin-top: 100px;
    max-width: 607px;
    width: 100%;
    height: 690px;
    border-radius: 21.224px;
    border: 1.061px solid #e0e0e0;
    background: #fff;
    box-shadow: 0px 87.02px 42.449px -14.857px rgba(100, 100, 100, 0.08);
    padding: 34px 113px;
    position: relative;
    // border: 1px solid red;
    .iconBack {
      position: absolute;
      top: 33px;
      left: 50px;
    }
    .signupCenterDiv {
      margin-top: 50px !important;
    }

    h5 {
      color: #212121;
      text-align: center;
      font-family: Inter;
      font-size: 33.163px;
      font-style: normal;
      font-weight: 700;
      line-height: 39.796px;
      letter-spacing: -0.531px;
    }
    .pass-recover-data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      // border: 1px solid purple;
      margin-top: 0 !important;
    }
    .signupUserData {
      margin-top: 40px !important;
    }
    .userData {
      margin-top: 100px;
      // border: 1px solid blue;

      form {
        // display: flex;
        // align-items: start;
        // justify-content: center;
        // flex-direction: column;
        // gap: 15px;
        .emailInput {
          label {
            color: rgba(0, 0, 0, 0.5);
            font-family: Inter;
            font-size: 13.37px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.134px;
          }
          input {
            width: 100%;
            height: 57.3px;
            border-radius: 9.55px;
            border: 0.955px solid rgba(0, 0, 0, 0.2);
            padding: 24px 17px 21px 21px;
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.153px;
            margin-bottom: 15px;
            position: relative;
            outline: none;
          }
        }
        .passwordInput {
          label {
            color: rgba(0, 0, 0, 0.5);
            font-family: Inter;
            font-size: 13.37px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.134px;
          }

          .pswd-div {
            position: relative;
            input {
              width: 100%;
              height: 57.3px;
              border-radius: 9.55px;
              border: 0.955px solid rgba(0, 0, 0, 0.2);
              padding: 18px 17px 21px 21px;
              color: #000;
              font-family: Inter;
              font-size: 16.28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.15px;
              margin-bottom: 15px;
              position: relative;
              outline: none;
              padding-right: 50px;
              display: flex;
              align-items: center;
           
              &::placeholder{
                padding-top: 20px !important;
            
              }
            }
          }

          .iconEye {
            position: absolute;
            top: 38%;
            right: 4%;
            color: rgb(43, 42, 42);
            cursor: pointer;
          }
        }

        .signInn {
          display: flex;
          width: 381px;
          height: 57px;
          padding: 16.033px 52.109px;
          justify-content: center;
          align-items: center;
          gap: 10.021px;
          border-radius: 6px;
          background: linear-gradient(106deg, #9b28a8 10.92%, #9208f3 88.71%);
          color: #fff;
          font-family: Inter;
          font-size: 22.046px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
          margin-bottom: 15px;
        }

        .forgot {
          button {
            background: none;
            color: #9208f3;
            font-family: Inter;
            font-size: 15.28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.153px;
            border: none;
          }
        }
        .or {
          font-family: Inter;
          font-size: 13.584px;
          font-style: normal;
          font-weight: 400;
        }
        .googleBtn {
          width: 197px;
          height: auto;
          margin: 0 auto;
          border-radius: 11px;
          border: 1px solid #eee;
          background: #fff;
         
          //border: 1px solid red;
          margin-top: 15px !important;

          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 11px;
            border: none;
            gap: 14px;
            height: auto;
            background: white;
            padding: 0;
           // border: 1px solid blue;
            width: auto;
            img {
            }
            span {
              color: #000;
              text-align: right;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 23.909px; /* 199.245% */
            }
          }
        }
      }
    }
  }
}
.signupCenterDiv {
  margin-top: 50px !important;
}
@media screen and (max-width: 1879px) {
  .signInwrapper .centerDiv {
    margin: 0 auto;
    margin-top: 23px;
  }
}
@media screen and (max-width: 1440px) {
  .signInwrapper {
    .nav {
      max-width: 90%;

      top: 10px;
      left: 5%;
      .logo {
        img {
          width: 130px;
        }
      }

      button {
        width: 122px;
        height: 47px;
        font-size: 15px;
      }
    }
    .centerDiv {
      min-height: 400px;
      height: auto;
      .pass-recover-data {
        margin-top: 30px !important;
      }

      .userData {
        margin-top: 30px;
        form {
          .emailInput {
            input {
              height: 50px;
            }
          }
        }
      }
    }
  }

  .signInwrapper .centerDiv .userData form .passwordInput .pswd-div input {
    height: 50px;
  }
}
@media screen and (max-width: 1350px) {
  .signInwrapper {
    .nav {
      // max-width: 95%;
      button {
        width: 132px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .signInwrapper {
    .nav {
      .logo {
        img {
          width: 120px;
        }
      }
      button {
        width: 118px;
        height: 45px;
        font-size: 15px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .signInwrapper {
    height: auto;
    padding: 20px;
    .nav {
      // max-width: 95%;
      margin-top: 0 !important;
      top: 11px;
      .logo {
        img {
          width: 85px;
        }
      }
      button {
        // width: 110.333px;
        // height: 47px;
        // font-size: 14.067px;

        width: 85.333px;
        height: 39px;
        font-size: 13.067px;
      }
    }
    .centerDiv {
      max-width: 100%;
      padding: 10px;
      height: auto;
      min-height: 300px;
      margin-top: 50px;

      h5 {
        font-size: 22px;
      }
      .pass-recover-data {
        height: 225px !important;
      }
      .signupUserData {
        margin-top: 0 !important;
      }
      h5 {
        font-size: 22px;
      }
      .iconBack {
        top: 11px;
        left: 15px;
        width: 30px;
      }
      .userData {
        //  margin-top: 30px;
        margin-top: 0;
        form {
          .signInn {
            width: 100%;
            height: 47px;
            font-size: 20px;
          }
          .emailInput {
            input {
              height: 48px;
              padding: 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .signInwrapper {
    .centerDiv {
      .pass-recover-data {
        height: 350px;
        form {
          width: 100%;
        }
      }
    }
  }

  .signInwrapper .centerDiv .userData form .passwordInput .pswd-div input {
    height: 47.3px;
    padding: 10px;
    font-size: 14px;
  }
}

.princingCardWrapper {
  display: flex;
  width: 410px;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  color: white;
  border-radius: 21px;
  border: 2px solid #ebebeb;
  background: linear-gradient(
    111deg,
    rgba(206, 46, 153, 0.17) -20.4%,
    rgba(97, 45, 163, 0.5) 19.63%,
    rgba(70, 41, 150, 0.5) 45.82%,
    rgba(81, 40, 149, 0.5) 73.48%,
    rgba(206, 46, 153, 0.5) 119.32%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(13px);


 
 
  .dPriceWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
   // border: 1px solid red;
    h4 {
      color: #FFF;
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 37.359px; /* 143.687% */
      letter-spacing: -1.185px;
    .priceText {
      color: #FFF;
      font-family: Inter;
      font-size: 10px !important;
      font-style: normal;
      font-weight: 400;
    
    
    }
  }

}
.message{
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.696px; /* 173.103% */
}
.duration{
  color: #FFF;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.572px; /* 175.275% */
  letter-spacing: -1.869px;
}
.price-btn-wrap {
  .gradient-btn {
      
      width: 163px;
      height: 43px;
   //   padding: 10.033px 52.109px;
         border-radius: 52px;
background: linear-gradient(106deg, #9B28A8 10.92%, #9208F3 88.71%);
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
border: none;
    }
  }

  ul {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.168px; /* 151.051% */
    letter-spacing: 0.074px;
    list-style: disc;
    margin-left: 20px;
    border-bottom: 1px solid rgb(212, 205, 205);
    padding-bottom: 10px;
    .listItem1 {
      li {
        letter-spacing: 0.074px;
        margin-bottom: 8px;
      }
      .exclame-img {
        width: 18.554px;
        height: 18.554px;
        object-fit: cover;
        margin-top: 5px;
      }
    }
  }
  .keyPara {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.168px; /* 201.401% */
    letter-spacing: 0.074px;
    margin-bottom: 8px;
  }
  .keyFeatures {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 13px;
    gap: 10px;
    margin-bottom: 10px;
    .keyLeft {
    //  margin-top: -13px;
    }
    .keyRight {
      color: #FFF;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.168px; /* 201.401% */
      letter-spacing: 0.074px;
      .featurePoints {
        margin-bottom: 3px;
      }
    }
  }
}


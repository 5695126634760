.guideCard {
  padding: 120px 80px;
  height: 547px;
  border-radius: 32px;
  //border: 1px solid purple;
  // background-color: rgb(122, 101, 141);
  background-image: url("../../assets/images/home/guideBg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  //margin-right: 200px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;

  .mainContainer {
    width: 540px;
    height: auto;
    // border: 2px solid green;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    align-self: flex-start;
    .personaTitle {
      color: var(--white, #fff);
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 56px;
      margin-bottom: 0;
    }
    p {
      color: var(--text-text-primary-invert, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 200% */
    }
    button {
      margin-top: 20px;
      display: flex;
      width: 91px;
      padding: 10px 14px;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 19.169px;
      // border: 1px solid #e9deff;
      background: #0e0026;
      box-shadow: 0px 0px 19px 9px rgba(162, 45, 247, 0.1),
        0px 0px 46.852px 0px rgba(162, 45, 247, 0.1),
        0px 0px 25.481px 0px rgba(162, 45, 247, 0.1),
        0px 0px 13px 0px rgba(162, 45, 247, 0.1),
        0px 0px 6.519px 0px rgba(162, 45, 247, 0.1),
        0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
      color: #e9deff;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-transform: uppercase;
      position: relative;

      &::after {
        background: linear-gradient(90deg, #9208f3 80%, #9b28a8 20%);
        border-radius: 25px;
        bottom: -1px;
        content: "";
        left: -1px;
        position: absolute;
        right: -1px;
        top: -1px;
        z-index: -1;
      }


    }
  }
}
@media screen and (max-width: 600px) {
  .guideCard {
    height: auto;
    padding: 20px;
    margin-top: 50px !important;
    margin-right: 40px;
    margin: 0 auto;
    width: 95%;
   // border: 1px solid red;
    .mainContainer {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      align-self: flex-start;
      .personaTitle {
        font-size: 25px;
        line-height: normal;
      }
      p {
        line-height: normal;
        text-align: center;
      }
    }
  }
}

.formInput {
  width: 100%;
  border-radius: 10px;
  background: #f9f9f9;
  padding: 13px 20px 11px 20px;
  color: #0e0026;
  margin-bottom: 20px;
  border: none;
  outline: none;
}
.modal-input {
  border-radius: 10px;
  background: #1e1e1e;
  width: 295px;
  height: 56px;
  padding: 16px 21px;
  font-size: 15.777px;
  font-weight: 500;
  color: #fff;
  border: none !important;
  &::placeholder {
    color: var(--Light-Grey, #959595);
  }
}
.search-container {
  position: relative;
  width: 295px; /* Match the input width */
}

.search-input {
  border-radius: 10px;
  background: #1e1e1e;
  width: 100%; /* Full width within the container */
  padding: 12px 16px 12px 40px; /* Space for the icon */
  font-size: 14px; /* Smaller font size */
  font-weight: 500;
  color: #fff;
  border: none !important;
}

.search-input::placeholder {
  color: var(--Light-Grey, #959595);
}

.search-icon {
  position: absolute;
  top: 62.5%;
  right: 16px; /* Align with input padding */
  transform: translateY(-50%);
  color: var(--Light-Grey, #959595);
  font-size: 16px; /* Adjust size for clarity */
  pointer-events: none; /* Ensure it doesn't block input interaction */
}
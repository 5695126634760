.Dashboard-main-container {
  min-height: 100vh;
  position: relative;
  .left-content {
    padding: 16px !important;
  }
  .right_content {
    padding: 36px 0px 0px 0px;
    width: calc(100vw - 252px);
    margin-left: 288px !important;
    .outlet-div {
      padding-right: 20px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .Dashboard-main-container {
    padding: 0px 20px 20px 20px !important;
    .right_content {
      padding: 20px 0px 0px 0px;
      width: 100% !important;
      margin-left: 0px !important;
      .outlet-div {
        padding-right: 0px;
      }
    }
    .left-content {
      padding: 0px !important;
    }
  }
}

.how-itworks-main {
  width: 100%;
  position: relative;

  .howItWork {
    height: auto;
    max-width: 70%;
    margin: 0 auto;
    position: relative;

    h2 {
      margin-bottom: 56px;
    }
    .mainDiv {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 40px;
      position: relative;

    
      .leftContent {
        width: 43%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 87px;
        //   border: 1px solid green;
        height: 100%;

        .dataWrapper {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 12px;
          h3 {
            margin-bottom: 0;

            color: #fff;
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.26px;
          }
          p {
            margin-bottom: 0;
            width: 100%;

            color: #fff;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.22px;
          }
        }
      }
      .rightContent {
        width: 44%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 87px;
        // border: 1px solid green;
        //margin-top: 132px;

        .dataRight {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 10px;

          h3 {
            margin-bottom: 0;

            color: #fff;
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.26px;
          }
          p {
            margin-bottom: 0;
            width: 100%;

            color: #fff;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.22px;
          }
        }
      }
    }
  }
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: -100px;
    background-image: url("../../assets/images/home/grid.png");
    right: 0;
    user-select: none;
  }
}
@media screen and (max-width: 1550px) {
  .how-itworks-main {
    .howItWork {
      max-width: 80%;

      .mainDiv {
        .leftContent {
          .dataWrapper {
            h3 {
              font-size: 23px;
            }
            p {
              font-size: 19px;
            }
          }
        }
        .rightContent {
          .dataRight {
            h3 {
              font-size: 23px;
            }
            p {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .how-itworks-main .howItWork h2 {
    font-size: 45px;
  }
  .how-itworks-main {
    .howItWork {
      .mainDiv {
        .leftContent {
          gap: 60px;
          .dataWrapper {
            h3 {
              font-size: 20px;
            }
            p {
              font-size: 16px;
            }
          }
        }
        .rightContent {
          gap: 60px;
          .dataRight {
            h3 {
              font-size: 20px;
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .how-itworks-main .howItWork h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 600px) {
  .how-itworks-main {
    .howItWork {
      max-width: 100%;
      padding: 20px;
      height: auto;
      h2 {
        font-size: 30px;
      }
      .mainDiv {
        flex-direction: column;
        .leftContent {
          width: 100%;
          gap: 30px;
          .dataWrapper {
            h3 {
              font-size: 22px;
            }
            p {
              font-size: 15px;
            }
          }
        }
        .rightContent {
          width: 100%;
          gap: 30px;
          margin-top: 50px;
          .dataRight {
            h3 {
              font-size: 22px;
            }
            p {
              font-size: 15px;
            }
          }
        }
      }
    }

    &::before {
      display: none;
    }
  }
}

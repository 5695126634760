.slarCardSection {
  width: 77%;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 140px;
  // position: relative;
  //border: 2px solid green;

  .withSlaraGlow {
    position: absolute;
    top: -500px;
    left: -296px;
    user-select: none;
  }

  .joinWaitlist {
    // margin-top: 100px;
    display: flex;
    width: 223.217px;
    height: 59.067px;
    // padding: 16.033px 52.109px;
    justify-content: center;
    align-items: center;
    gap: 10.021px;
    border-radius: 52px;
    background: linear-gradient(106deg, #9b28a8 10.92%, #9208f3 88.71%);
    color: #fff;
    font-family: Inter;
    font-size: 22.046px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .slarCardsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // border: 2px solid #b8ca15;
    width: 100%;
    margin-top: 120px;
    gap: 30px;
  }
}
@media screen and (max-width: 1600px) {
  .slarCardSection {
    width: 90%;
    .slarCardsWrapper {
      margin-top: 100px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .slarCardSection {
    margin-bottom: 100px;
    .joinWaitlist {
      margin-top: 70px;
      margin-bottom: 70px;
    }
    h2 {
      font-size: 45px;
    }
    .slarCardsWrapper {
    }
  }
}
@media screen and (max-width: 600px) {
  .slarCardSection {
    margin-bottom: 50px;
    .withSlaraGlow{
      display: none;
    }
    h2 {
      font-size: 30px;
    }
    .joinWaitlist {
      width: 180.217px;
      height: 48.067px;
      font-size: 17.046px;
    }
    .slarCardsWrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      margin-top: 20px;
      gap: 30px;
    }
  }
}

// @media screen and (max-width: 1879px) {
//   .slarCardSection {
//     padding: 20px;
//     width: 100%;
//     h2 {
//       width: 83%;
//       margin: 0 auto;
//       // border: 1px solid green;
//     }
//     .slarCardsWrapper {
//       //justify-content: space-between;
//     }
//   }
// }
// @media screen and (max-width: 1440px) {
//   .slarCardSection {
//     margin-top: 100px;
//     max-width: 80%;
//     padding: 0;
//     h2 {
//       width: 100%;
//       font-size: 45px;
//     }
//     .slarCardsWrapper {
//     }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .slarCardSection {
//     max-width: 90%;
//     h2 {
//       font-size: 40px;
//     }
//   }
// }
// @media screen and (max-width: 600px) {
//   .slarCardSection {
//     height: auto;
//     margin-top: 100px;
//     margin-bottom: 50px;
//     max-width: 100%;
//     padding: 20px;

//     h2 {
//       font-size: 30px;
//     }
//     .withSlaraGlow {
//       display: none;
//     }
//     .slarCardsWrapper {
//       flex-direction: column;
//       margin-top: 20px;
//     }
//   }
// }

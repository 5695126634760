.contact-main {
  position: relative;
  margin-top: 100px;
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: -115px;
    background-image: url("../../assets/images/home/grid.png");
    right: 0;
    user-select: none;
  }
  .contact {
    max-width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 56px;
    color: white;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0px;
      background-image: url("../../assets/images/home/grid.png");
      right: 0;
    }

    .contactGlow {
      position: absolute;
      top: -800px;
      right: -285px;
    }
    .large {
      font-size: 22px;
      letter-spacing: -0.22px;
    }
    button {
      display: inline-flex;
      padding: 16.033px 52.109px;
      justify-content: flex-end;
      align-items: center;
      gap: 10.021px;
      border-radius: 52px;
      background: linear-gradient(106deg, #9b28a8 10.92%, #9208f3 88.71%);
      color: #fff;
      font-family: Inter;
      font-size: 22.046px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      border: none;
    }
  }
}

@media screen and (max-width: 1800px) {
  .contact-main {
    .contact {
      margin-top: 50px;
      gap: 25px;
      .contactGlow {
        display: none;
      }
      .large {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .contact-main {
  
    .contact {
      max-width: 80%;
      h2{
        font-size: 45px;
      }
      .large{
        font-size: 17px !important;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .contact-main{
    .contact{
      h2{
        font-size: 40px;
      }
    }
  }

}
@media screen and (max-height: 800px) {
  .contact {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .contact-main {
    margin-top: 20px !important;
    height: auto;
    .contact {
      max-width: 100%;
    
      padding: 20px;
      gap: 7px;
      justify-content: center;
      align-items: center;
      .contactGlow {
        display: none;
      }
      h2 {
        text-align: center;
        font-size: 30px;
      }
      .large {
        font-size: 15px !important;
        text-align: center;
      }
      button {
        margin-top: 20px;
        padding: 16.033px 22.109px;
        font-size: 18.046px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .contact-main{
    margin-top: 100px;
  }
}
@media screen and (max-width: 400px) {
  .contact-main{
    margin-top: 200px;
  }
}
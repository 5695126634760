.settings-main-conatainer {
  max-width: 1092px;
  padding: 109px 88px 249px 88px;
  margin-top: 55px;
  margin-right: 42px;
  margin-bottom: 20px;
  position: relative;
  gap: 104px;
  width: 100%;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 735px;
    background-image: url(../../../../public/assets/dashboardimages/images/bg-border.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
  .back-icon {
    position: absolute;
    top: 45px;
    left: 45px;
  }
  .profile-img {
    // border: 3px solid #e1e1e1;
    border-radius: 50%;
    width: 140.098px;
    height: 140.098px;
    position: relative;
    overflow: hidden;
    img {
      width: 140.098px;
      height: 140.098px;
    }
    .change-pic-div {
      position: absolute;
      top: 38px;
      right: 22px;
      width: 96px;
      gap: 8px;
    }
  }
  .right-container {
    gap: 38px;
    h4,
    label {
      color: var(--Primary-2, #9208f3);
    }
    form {
      gap: 26px;
      input {
        width: 427px !important;
        z-index: 9;
      }
      .setting-email-input {
        position: relative;
        input {
          padding-right: 100px;
        }
        button {
          z-index: 99;
          position: absolute;
          width: 88px;
          right: 1%;
          height: 46px;
          top: 8%;
        }
      }
      .setting-pswd-input {
        position: relative;
        .eye-icon-setting {
          position: absolute;
          top: 34%;
          left: 5%;
          z-index: 99;
        }
        input {
          padding-right: 100px;
          padding-left: 60px;
        }
        button {
          z-index: 99;
          position: absolute;
          width: 88px;
          right: 1%;
          height: 46px;
          top: 8%;
        }
      }
    }
  }
  .details-div {
    gap: 26px;
    button {
      border-radius: 10px;
      background: #1e1e1e;
      max-width: 427px !important;
      width: 100% !important;
      height: 56px;
      padding: 16px 21px;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.updatepswd-container {
  padding-top: 60px !important;
}
.save-btn {
  margin-top: 20px !important;
}
.error-msgs {
  max-width: 300px;
}
@media only screen and (max-width: 1200px) {
  .settings-main-conatainer {
    padding: 45px 40px 249px 40px;
    gap: 84px;

    .right-container {
      form {
        input {
          width: 387px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .settings-main-conatainer {
    padding: 70px 20px 20px 20px;
    flex-direction: column;
    margin-right: 0px !important;
    gap: 20px;
    .back-icon {
      top: 25px;
      left: 15px;
    }
    .profile-img {
      margin: auto;
      width: 120.098px;
      height: 120.098px;
      img {
        width: 120.098px;
        height: 120.098px;
      }
      .change-pic-div {
        top: 28px;
        right: 12px;
      }
    }
    .right-container {
      form {
        input {
          width: 100% !important;
          z-index: 9;
        }
        .setting-email-input {
          button {
            width: 78px;
            font-size: 14px;
          }
        }
        .setting-pswd-input {
          button {
            width: 78px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .updatepswd-container {
    padding-top: 90px !important;
  }
}

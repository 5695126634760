.models-main-container {
  padding: 50px 20px 20px 0px;
  width: 100%;
  span {
    gap: 5px;
    p {
      width: 20px;
      height: 1px;
      opacity: 0.2;
      background: var(--Light-Grey, #959595);
    }
  }
  .history-card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;

    .model-history-card {
      position: relative;
      padding: 14px 15px 14px 10px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.05);
      // max-width: 327px;
      width: 100%;
      gap: 28px;
      button {
        width: 165px;
        height: 38px;
      }
      .delIcon {
        position: absolute;
        right: 1%;
        top: 10%;
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .models-main-container {
    .history-card-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media only screen and (max-width: 1100px) {
  .models-main-container {
    .history-card-wrapper {
      .model-history-card {
        span {
          font-size: 14px;
        }
        .del-icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .models-main-container {
    padding: 30px 0px 20px 0px;
    .history-card-wrapper {
      grid-template-columns: repeat(1, 1fr);

      .model-history-card {
        max-width: 100%;
      }
    }
  }
}

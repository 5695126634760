.pricingWrapper{
    padding: 20px;
   // margin-top: 82px;

   .mainDiv{
    margin-top: 162px !important;
    width:70%;
margin: 0 auto;
    // border: 1px solid yellow;
    h2{
        margin-bottom:62px;
    }

    .pricingCardContainer{
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
    }
   }

}


.pricingSingleCard{
    .container{
        .tabMainParent{
            margin-top: 20px;
            .bg-Set-Card{
                button{
                
                }
                        }
        }
       
    }
}

@media screen and (max-width:600px) {

    
.pricingSingleCard{
    .container{
        h2{
            width: 100%;
        }
      p{
        width: 100%;
      }
        .tabMainParent{
            width: 100% !important;
            overflow-x: hidden !important;
            .bg-Set-Card{
             
               width:100% !important;
               margin: 0px !important;
               margin-left: 0px !important;

    gap: 10px;
    button{
       font-size: 14px;
       padding-left: 5px !important;
       padding-right: 5px !important;
    }
            }
        }
     
    }
}
}
.sidebar-main-container {
  display: flex;
  max-width: 252px;
  width: 100%;
  flex-direction: column;
  position: fixed;
  border-radius: 22px;
  background: linear-gradient(
    176deg,
    #0e0026 1.49%,
    #0e0026 64.82%,
    #9208f3 99.38%
  );
  box-shadow: 0px 0px 36.1px 0px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 30px);
  transition: width 0.3s ease-in-out;
  .sidebar-logo-container {
    padding: 25px 0px;
    position: relative;
    border-bottom: 1px solid #241838;
    .logo {
      margin: auto;
    }
  }
  .sidebar-options-container {
    padding: 25px 19px 19px 19px;
    height: 100%;
    ul {
      height: 100%;
      li {
        .dashLinks {
          position: relative;
          padding: 11px 84px 10px 10px;
          color: var(--white);
          font-weight: 500;
          margin-bottom: 15px;
          height: 45px;
          max-width: 214px !important;
          .sidebar-img {
            margin-right: 14px;
            width: 24px;
            height: 24px;
          }

          &.active,
          &:hover {
            border-radius: 6px;
            background: linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%);
          }
        }
      }
    }
    button {
      border-radius: 6px;
      background: linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%);
      width: 214px;
      height: 45px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar-main-container {
    display: none;
  }
  .mobile-sidebar {
    display: block;
  }
  .mobile-menu-drawer {
    background: none !important;
    padding: 0;
  }
  .sidebar-container {
    display: flex;
    max-width: 252px;
    width: 100%;
    flex-direction: column;
    position: fixed;
    border-radius: 22px;
    background: linear-gradient(
      176deg,
      #0e0026 1.49%,
      #0e0026 64.82%,
      #9208f3 99.38%
    );
    box-shadow: 0px 0px 36.1px 0px rgba(0, 0, 0, 0.08);
    height: calc(100vh - 30px);
    transition: width 0.3s ease-in-out;
    .sidebar-logo-container {
      padding: 25px 0px;
      position: relative;
      .logo {
        margin: auto;
      }
      .backarrow-Logo {
        position: absolute;
        width: 40px;
        height: 40px;
        right: -6%;
        top: 19%;
      }
    }
    .sidebar-options-container {
      padding: 0px 19px 19px 19px;
      height: 100%;
      ul {
        height: 100%;
        li {
          margin-left: 0px !important;
          .dashLinks {
            position: relative;
            padding: 11px 84px 10px 10px;
            color: var(--white);
            font-weight: 500;
            margin-bottom: 10px;
            height: 45px;
            width: 214px;

            .sidebar-img {
              margin-right: 14px;
              width: 24px;
              height: 24px;
            }

            &.active {
              border-radius: 6px;
              background: linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%);
            }
          }
        }
      }
      button {
        border-radius: 6px;
        background: linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%);
        width: 214px;
        height: 45px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  :where(.css-dev-only-do-not-override-42nv3w).ant-drawer .ant-drawer-body,
  .ant-drawer,
  .ant-drawer-body {
    padding: 0px !important;
  }
  :where(.css-dev-only-do-not-override-42nv3w).ant-drawer-left
    > .ant-drawer-content-wrapper,
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
}

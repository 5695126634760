.featureCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 120px;
  //  border: 1px solid purple;
  .leftArea {
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    //  border: 1px solid red;

    .heading {
      color: var(--blue-blue-000, #fff);
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.48px;
      margin-bottom: 0;
    }
    p {
      color: var(--blue-blue-000, #fff);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      // margin-bottom: 30px;
    }
    ul {
      margin-top: 30px;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        margin-bottom: 25px;
        img {
          width: 23px;
        }

        span {
          color: var(--blue-blue-000, #fff);
          font-feature-settings: "clig" off, "liga" off;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 38.4px */
        }
      }
    }
    button {
      margin-top: 20px;
      display: inline-flex;
      padding: 10px 14px;
      align-items: center;
      gap: 10px;
      border-radius: 19.169px;
      // border: 1px solid #e9deff;
      background: #0e0026;
      box-shadow: 0px 0px 19px 9px rgba(162, 45, 247, 0.1),
        0px 0px 46.852px 0px rgba(162, 45, 247, 0.1),
        0px 0px 25.481px 0px rgba(162, 45, 247, 0.1),
        0px 0px 13px 0px rgba(162, 45, 247, 0.1),
        0px 0px 6.519px 0px rgba(162, 45, 247, 0.1),
        0px 0px 3.148px 0px rgba(162, 45, 247, 0.1);
      color: #e9deff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.14px;
      text-transform: uppercase;
      position: relative;
      &::after {
        background: linear-gradient(90deg, #9208f3 80%, #9b28a8 20%);
        border-radius: 25px;
        bottom: -1px;
        content: "";
        left: -1px;
        position: absolute;
        right: -1px;
        top: -1px;
        z-index: -1;
      }
    }
  }
  .rightArea {
    width: 56%;
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 100%;
    // border: 1px solid red;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.cardSwap {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1700px) {
  .featureCard {
    gap: 100px;
    .leftArea {
      .heading {
        font-size: 42px;
      }
      ul {
        margin-top: 0;
      }
      button {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .featureCard {
    align-items: center;
    .leftArea {
      .heading {
        font-size: 30px;
      }
      p {
        font-size: 18px;
        line-height: normal;
      }
      ul {
        li {
          margin-bottom: 10px;
          img {
            width: 20px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .featureCard {
    gap: 50px;

    .leftArea {
      .heading {
        font-size: 28px;
      }
      p {
        font-size: 17px;
        line-height: normal;
      }
      ul {
        li {
          margin-bottom: 10px;
          img {
            width: 18px;
          }
          span {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .featureCard {
    flex-direction: column;
    .leftArea {
      width: 100%;
      gap: 28px;
      align-items: center;
      .heading {
        font-size: 21px;
      }
      p {
        font-size: 16px;
        text-align: center;
      }
      ul {
        padding-top: 0;
        li {
          margin-left: 0 !important;
          align-items: flex-start;
          span{
            line-height: normal;
            font-size: 16px;
          }
        }
      }
    }
    .rightArea {
      width: 100%;
    }
  }
}

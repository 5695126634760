.marqueSection {
  padding: 20px;
  .inner {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
    p {
      color: #fff;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
    }
    .brandsContainer{
 
    .logoWrapper22 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 70px;
 
    }
    }
  }
}
@media screen and (max-width: 1700px){
  .marqueSection{
    .inner{
.logoWrapper{

}
    }
  }

}
@media screen and (max-width: 1440px) {
  .marqueSection {
    margin-top: 100px;
    margin-bottom: 100px;
    .inner {
       // width: 100%;
      .logoWrapper {
        img {
          width: 140px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .marqueSection {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    .inner {
      width: 95%;
     // overflow-x:auto;
      //padding: 20px;
      gap: 20px;
      p {
        text-align: center;
      }
      .logoWrapper{
        display: none !important;
      }
      .brandsContainer{
        display: flex !important;
        .logoWrapper22 {
          width: auto;
          padding: 10px;
           display: flex;
          align-items: center;
          justify-content: center;
          gap: 40px;
   
          img {
            width: 120px;
          }
        }
      }
 
    }
  }
}

.discussion-main-container {
  margin: 55px 20px 30px 0px;
  border-radius: 40px;
  position: relative;
  max-width: 1092px;
  min-height: 735px;
  width: 100%;
  height: 100%;
  padding: 37px 0px 37px 0px;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 735px;
    background-image: url(../../../../public/assets/dashboardimages/images/bg-border.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
  .dis-top {
    padding: 0px 0px 0px 44px;
  }
  .dic-inner-main {
    padding: 0px 0px 0px 22px;
    margin-top: 38px;
    gap: 40px;
    .disc-left {
      .disc-card-top {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        .flag-icon {
          margin-left: 8px;
          margin-right: 8px;
          width: 24px;
          height: 17.343px;
        }
        .inner-card {
          margin-left: 40px;
        }
      }
      .user-card {
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.05);
        padding: 14px 10px;
        max-width: 252px;
        min-height: 147px;
        .user-card-bottom {
          border-top: 1px dashed var(--Primary-1, #9b28a8);
          margin-top: 16px;
          padding: 16px 0px 16px 0px;
        }
      }
    }
    .disc-right {
      width: 420px;
      height: 512px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.05);
      .transcript-div {
        border-radius: 7px 7px 0px 0px;
        background: var(--Primary-2, #9208f3);
        height: 30px;
      }
      .chat-div {
        gap: 10px;
        padding: 13px 8px;
        overflow-y: auto;
        .right-div {
          max-width: 236px;
          width: 100%;
          border-radius: 6px 6px 0px 6px;
          background: var(--Primary-2, #9208f3);
          padding: 4.762px 9.524px;
          width: fit-content;
          margin-left: auto;
          .top {
            justify-content: flex-end;
            img {
              width: 25.359px;
              height: 25.359px;
            }
          }
          .bottom {
            justify-content: flex-end;
            span {
              font-size: 10px;
            }
          }
        }
        .left-div {
          max-width: 236px;
          width: 100%;
          border-radius: 6px 6px 6px 0px;
          background: #959595;
          padding: 4.762px 9.524px;
          width: fit-content;
          margin-right: auto;
          .top {
            justify-content: flex-end;
            img {
              width: 25.359px;
              height: 25.359px;
            }
          }
          .bottom {
            justify-content: flex-end;
            span {
              font-size: 10px;
            }
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.2em; // Adjust as needed for consistency
          margin: 0.5em 0;
          font-weight: 600;
          color: #fff; // Ensure readability on background
        }

        // Styling for Unordered Lists
        ul {
          list-style-type: disc;
          margin-left: 1.5em; // Proper indentation
          padding-left: 0;
          color: #fff; // Ensure readability on background
        }

        ul li {
          margin: 0.3em 0;
          font-size: 1em; // Consistent with other text
        }

        // Styling for Ordered Lists
        ol {
          list-style-type: decimal;
          margin-left: 1.5em; // Proper indentation
          padding-left: 0;
          color: #fff; // Ensure readability on background
        }

        ol li {
          margin: 0.3em 0;
          font-size: 1em; // Consistent with other text
        }

        // General paragraph styling for Markdown content
        p {
          margin: 0.5em 0;
          font-size: 1em;
          color: #fff; // Ensure readability on background
        }

        // Ensure no extra spaces around inline code
        code {
          padding: 0.2em 0.4em;
          font-size: 0.95em; // Adjust as needed
          background-color: #333; // Background for inline code
          border-radius: 4px;
          color: #fff; // Text color for readability
        }
      }
    }
  }
  .buttons {
    img {
      width: 66px;
      height: 66px;
    }
  }
  .button-div {
    button {
      width: 222px;
      height: 44px;
      border-radius: 5px;
      background: #f5382f;
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
  }
  .disc-bottom {
    padding: 14px 16px;
    width: 100%;
    max-width: 918px;
    margin-top: 48px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    justify-content: space-between;
    .blurry-input-div {
      // width: 100%;
      backdrop-filter: blur(10px);

      button {
        border-radius: 6px;
        background: linear-gradient(133deg, #9208f36e 2.91%, #9b28a86e 100%);
        width: 100%;
        height: 35px;
        color: #fff;
        

      }
    }
    input {
      height: 32px;
      width: 100%;
      background: transparent;
      border: none;
      z-index: 99;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      &::placeholder {
        color: var(--light-grey);
      }
    }
    .bottom-right {
      gap: 30px;
    }
  }
  .message-input {
    width: 100%;
  }
}
@media only screen and (max-width: 1430px) {
  .discussion-main-container {
    .dic-inner-main {
      .disc-left {
        .disc-card-top {
          flex-wrap: wrap;
          .inner-card {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
      }
    }
    .disc-bottom {
      max-width: 90% !important;
    }
  }
}
@media only screen and (max-width: 1217px) {
  .discussion-main-container {
    margin: 55px 0px 30px 0px;
    padding: 30px 20px 20px 30px;
    &::before {
      background-size: cover !important;
    }
    .dic-inner-main {
      flex-direction: column;
      .disc-left {
        .disc-card-top {
          h5 {
            font-size: 16px;
          }
          display: grid !important;
          grid-template-columns: repeat(1, 1fr) !important;
          .persona-card {
            max-width: 100% !important;
            .discussion-card-p{
              text-wrap: wrap!important;
            }
          }
          .flag-icon {
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            margin: auto;
          }
          .inner-card {
            margin-left: 0px;
          }
        }
        .part-img {
          width: 100%;
        }
        .user-card {
          max-width: 100% !important;
        }
      }
      .disc-right {
        width: 100%;
        .chat-div {
          .right-div {
            max-width: 336px;
          }
          .left-div {
            max-width: 336px;
          }
        }
      }
      .buttons,
      .button-div {
        margin: auto !important;
      }
    }
    .b-main {
      padding: 0px 0px 0px 44px;
    }
    .buttons {
      margin-top: 20px;
      order: 2;
      img {
        width: 46px;
        height: 46px;
      }
    }
    .button-div {
      margin-top: 20px;
      button {
        width: 200px;
        font-size: 18px;
      }
    }
    .disc-bottom {
      width: 100%;
      max-width: 76% !important;
      margin-top: 20px;

    }
  }

  .part-img {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .discussion-main-container {
    padding: 37px 0px !important;
    &::before {
      min-height: 1730px;
      background-size: auto !important;
      height: 100%;
    }
    .dis-top {
      padding: 0px 0px 0px 20px !important;
    }
    .dic-inner-main,
    .b-main {
      padding: 0px 0px 0px 20px !important;
    }
    .disc-bottom {
      max-width: 100% !important;
      margin-top: 10px !important;
      order: 1;
      padding: 14px 10px;
      .bottom-right {
        gap: 10px;
      }
      .next-arrow {
        width: 30px;
        height: 30px;
      }
    }
    .buttons {
      order: 2;
      margin-top: 20px;
    }
  }
}

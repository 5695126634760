.contactUsPage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 100px;
 // border: 2px solid blue;


}
.contactBg {
 // background-color: red;
  width: 895px;
  margin: 0 auto;
  height: auto;
}
.mainDivBg {
  width: 100%;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .contactUsPage {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

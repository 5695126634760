.feedback-main-container {
  margin: 55px 20px 30px 0px;
  border-radius: 40px;
  position: relative;
  max-width: 1092px;
  min-height: 735px;
  width: 100%;
  height: 100%;
  padding: 37px 44px;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 735px;
    background-image: url(../../../../public/assets/dashboardimages/images/bg-border.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
  p {
    margin-top: 7px;
  }
  .feedback-cards-wrapper {
    margin-top: 18px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    padding: 25px;
    max-width: 473px;
    width: 100%;
    gap: 16px;
    .feedback-card {
      gap: 43px;
      display: flex;
      .card-left {
        flex-basis: 150px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.05);
        padding: 18px 20px;
        img {
          width: 43.682px;
          height: 47px;
        }
      }
      .card-right {
        gap: 15px;
        .stars-div {
          gap: 8.8px;
        }
        .thumbs-div {
          gap: 7px;
          .thumbs-down {
            transform: scaleX(-1);
          }
          .thumbs-up,
          .thumbs-down {
            &:hover {
              color: #ffffff !important ;
            }
          }
        }
      }
    }
    .button-wrapper {
      margin-top: 20px;
      button {
        width: 189px;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .feedback-main-container {
    padding: 20px;
    p {
      margin-top: 7px;
    }
    .feedback-cards-wrapper {
      padding: 20px;
      .feedback-card {
        gap: 20px;
        .card-left {
          padding: 10px 15px;
          img {
            width: 43.682px;
            height: 47px;
          }
          h6 {
            font-size: 14px;
          }
        }
        .card-right {
          gap: 15px;
          .stars-div {
            gap: 4px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .thumbs-div {
            .thumbs-down,
            .thumbs-up {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

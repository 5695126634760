.features {
  padding: 20px;
//border: 1px solid red;
  margin-bottom: 170px;
  position: relative;

  h2 {
    margin-bottom: 120px;
    color: var(--blue-blue-000, #fff);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
  }
  .innerSection {
    width: 77%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 172px;
    flex-direction: column;
    //border: 1px solid green;
  }
  .featGlow{
position: absolute;
top:-300px;
right: 0;
z-index: -1;
  }
}

@media screen and (max-width: 1500px){

 
  .features{
    margin-bottom: 70px;
    h2{
      font-size: 50px;
    }
    .innerSection{
      width: 90%;
      gap: 100px;
    }
  }
}
@media screen and (max-width: 1200px){
  .features{
    .innerSection{
      width: 90%;
    }
  }
}


@media screen and (max-width: 600px){
  .features{
    margin-bottom: 50px;
    h2{
      font-size: 30px;
      margin-bottom: 20px;
    }
    .innerSection{
      width: 95%;
      flex-direction: column;
      gap: 50px;
      
    }
  }
}
.testimonialCard {
  max-width: 1080px;
  margin: 0 auto;
  margin-right: 50px;
  height: 467px;
  border-radius: 42px;
  // border-top: 3px solid #fff;
  // border-bottom: 3px solid #fff;
  // border-left: 3px solid #fff;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(10px);
  color: white;
  padding: 32px 58px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../assets/images/home/layer.png");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    user-select: none;
    z-index: -1;
    background-size: cover;
  }

  // .testiText {

  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   z-index: -1;
  //   width: 100%;
  // //  user-select: none;

  // }
  p {
    margin-bottom: 0;
    color: var(--blue-blue-000, #fff);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.6%; /* 29.988px */
  }
  h3 {
    font-weight: 700;
  }

  .read-or-hide {
    background: none;
    border: none;
    color: #8a10e0;
    cursor: pointer;
    font-size: 1em;
    padding: 0;
    font-weight: bold;

    &:hover {
      color: #8a10e0;
      text-decoration: none !important;
    }

    &:active {
      color: #8a10e0;
      text-decoration: none !important;
    }
  }
}

@media screen and (max-width: 1550px) {
  .testimonialCard {
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1400px) {
  .testimonialCard {
    //min-height: 467px;
    //height: auto;
    height: 355px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;

    &::before {
      height: 355px !important;
      //border: 1px solid red;
      background-size: 100% 100%;
    }
    p {
      font-size: 17.5px;
    }
  }
}
@media screen and (max-width: 1330px) {
  .testimonialCard {
    // min-height: 556px;
    max-width: 850px;
    //  height: 400px;
    &::before {
      //  height: 400px !important;
      //border: 1px solid red;
      background-size: 100% 100%;
    }
    .testiText {
      // display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 600px) {
  .testimonialCard {
    height: auto !important;

    max-width: 100%;
    width: 95%;
    padding: 20px;
    border-radius: 25px;
    margin-right: 0px;
    &::before {
      height: auto !important;
      max-height: 100%;
    }
    h3 {
      font-size: 21px;
    }
    p {
      font-size: 16px;
    }
  }
}

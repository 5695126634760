.dashboard-main {
  padding: 77px 20px 77px 0px;
  position: relative;
  width: fit-content;
  .welcome-cards-wrapper {
    margin-top: 34px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 12px;
    column-gap: 12px;
    width: 100%;
    .welcome-card {
      gap: 12px;
      width: 100%;
      height: 176px;
      border-radius: 8px;
      padding: 20px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 176px !important;
        background-image: url(../../../../public/assets/dashboardimages/images/cards-bg-border.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: -1;
      }
    }
  }
  .dashboard-bottom {
    margin-top: 54px;
    .buttons-div {
      gap: 16px;
      margin-top: 40px;
      button {
        width: 235px;
        height: 49px;
      }
    }
  }
}
.custom-dashboard-class {
  width: 100% !important;
}

.choose-btn {
  &:hover {
    color: black;
  }
}
@media only screen and (max-width: 700px) {
  .dashboard-main {
    padding: 30px 0px 20px 0px;
    .welcome-cards-wrapper {
      margin-top: 24px;
      grid-template-columns: repeat(1, 1fr);
      .welcome-card {
        height: 176px;
        padding: 20px;
      }
    }
    .dashboard-bottom {
      .buttons-div {
        flex-direction: column;
      }
    }
  }
}

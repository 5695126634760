.bg-Set-Card {
  background: linear-gradient(95deg, #371541, #2a174f);
  margin: 1rem;
}
.bgSet {
  background: #9b28a8;
  color: white;
}
.bgSet1 {
  background-color: transparent;
  color: white;
}
.mtSet {
  margin-top: 3% !important;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
}
.CardPriceSetForSmall {
  z-index: 10 !important;
  box-shadow: 0px 0px 15px #7d6194;
  /* position: relative !important; */
  /* height: 914px !important; */
}
.custom-checkbox {
  width: 25px;
  height: 20px;
  border-radius: 50%;
  appearance: none;
  background-color: #441573;

  cursor: pointer;
  position: relative;
}
.custom-checkbox:checked {
  /* background-color: #007bff; */
}
.custom-checkbox:checked::after {
  content: "\2713";
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.setHeight {
  min-height: 600px !important;
  height: auto;
  margin-top: 30px !important;
}
.bg-9b28a8 {
  background-color: #9b28a8;
  color: white; /* The selected background color */
}

.bg-transparent {
  background-color: transparent;
  color: white; /* Transparent background */
}

@media screen and (max-width: 980px) {
  .SetForSmall {
    display: flex !important;
    flex-direction: column !important;
  }
  .CardPriceSetForSmall {
    margin-top: 10% !important;
  }
  .CardPriceSetForSmall1 {
    margin-top: 10% !important;
    margin-bottom: 5% !important;
  }
  .mtSet {
    width: 50% !important;
  }
}

.conv-main-container {
  margin: 55px 20px 30px 0px;
  width: 100%;
  .con-top {
    button {
      width: 178px;
      height: 36px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  span {
    gap: 5px;
    p {
      width: 20px;
      height: 1px;
      opacity: 0.2;
      background: var(--Light-Grey, #959595);
    }
  }
  .convo-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    .convo-card {
      padding: 14px 10px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.05);
      gap: 12px;
      .card-bottom {
        .bottom-left {
          img {
            width: 43px;
            height: 47px;
          }
          gap: 14px;
        }
        button {
          width: 61px;
          height: 38px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1170px) {
  .conv-main-container {
    .convo-card {
      span {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 1070px) {
  .conv-main-container {
    .convo-card-wrapper {
      .convo-card {
        .card-bottom {
          .bottom-left {
            h6 {
              font-size: 14px;
            }
            img {
              width: 43px;
              height: 47px;
            }
            gap: 6px;
          }
          button {
            width: 50px;
            height: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .conv-main-container {
    .con-top {
      flex-direction: column;
      gap: 10px;
    }
    .convo-card-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px #dedddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(
    --2-color-grad,
    linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%)
  );
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(
    --2-color-grad,
    linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%)
  );
}

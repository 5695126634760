.footer{
    border-top: 0.5px solid #313131;
    padding: 24px;
    .part1{

    }
    .part2{

    }
    p{
        margin-bottom: 0;
    }
}

@media screen and (max-width:600px) {
    .footer{
        display: flex;
        flex-direction: column;
        gap: 30px;
        .part1{
            width: 100% !important;
            justify-content: center !important;
            p{
                text-align: center;
            }
        }
        .part2{
            width: 100% !important;
            flex-direction: column;
            align-items: center;
            p{
                width: 100%;
                text-align: center;
            }

        }
    }
    
}
.edidpersona-main-container {
  gap: 26px;
  margin-top: 27px;
  .editpersona-top-div {
    gap: 26px;
    overflow-x: auto;
    width: 100%;
    max-width: 1400px;
    padding-bottom: 20px;
    .edit-persona-card {
      max-width: 331px;
      width: 100%;
      padding: 18px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.05);
      .upload-img-div {
        gap: 29px;
        img {
          width: 79px;
          height: 85px;
          border: 1px solid #ffffff;
          border-radius: 8px;
        }
        button {
          width: 163px;
        }
      }
      .desc-box {
        textarea {
          min-height: 172px;
          height: 100%;
          &:focus {
            outline: none !important;
            box-shadow: none !important;
            border-radius: 10px;
            background: #1e1e1e;
            border: 1.5px solid #1e1e1e !important;
          }
        }
      }
      .buttons {
        button {
          width: 129px;
          height: 41px;
          font-size: 20px;
        }
      }
    }
  }
  .editpersona-bottom-div {
    justify-content: flex-end;
    button {
      width: 155px;
      height: 49px;

      font-size: 20px;
    }
  }
}
.myaccordion .card,
.myaccordion .card:last-child .card-header {
  border: none;
}
.card {
  background: transparent !important;
}
.card-header {
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  background: #1e1e1e;
  width: 295px;
  height: 56px;
  padding: 0px 21px;
  border: none !important;
}
.myaccordion .btn-link:hover,
.myaccordion .btn-link:focus {
  text-decoration: none;
  box-shadow: none !important;
  transition: transform 0.3s ease !important;
}
.btn.collapsed .adv-icon {
  transform: rotate(180deg);
}
.myaccordion .btn {
  width: 100%;
  font-size: 15.777px;
  font-weight: 500;
  color: var(--Light-Grey, #959595);
  padding: 0;
  --bs-btn-hover-color: var(--Light-Grey, #959595) !important;
  display: flex;
  justify-content: space-between;
}
.btn-link {
  font-size: 15.777px;
  font-weight: 500;
  color: var(--Light-Grey, #959595);
  --bs-btn-hover-color: var(--Light-Grey, #959595) !important;
  border: none !important;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--Light-Grey, #959595) !important;
  background-color: transparent !important;
  border: none !important;
}
.card-body {
  padding: 0px !important;
  margin-top: 10px;
  max-height: 118px;
  overflow-y: auto;
}
.stability-div {
  border-radius: 10px;
  background: #1e1e1e;
  width: 295px;
  padding: 18px 23px 4px 23px;
  height: 85px;
  p {
    font-size: 15.777px;
  }
}
.ant-slider-horizontal {
  width: 211px !important;
  background: #fff !important;
}
.custom-drop-inner {
  border-radius: 10px;
  background: #1e1e1e;
  padding: 16px 21px;
  margin-right: 5px;
  p {
    &:hover {
      color: var(--white) !important;
    }
  }
}
.ant-slider,
.ant-slider-track {
  height: 2px !important;
  background: #fff !important;
}
.ant-slider-horizontal {
  padding-block: 0px !important;
}
.adv-icon.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.adv-icon.collapsed {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
.ant-slider-handle {
  inset-block-start: -4px !important;
  &::after {
    background-color: #9208f3 !important;
  }
}
.ant-slider-tracks {
  background-color: white !important;
  transition: background-color 0.2s;
}
.speaker-dropdown {
  .rc-virtual-list-holder {
    height: 150px !important;
  }
}
.persona-dropdown {
  .rc-virtual-list-holder {
    height: 150px !important;
  }
}

:where(.css-dev-only-do-not-override-17seli4).ant-select:hover
  .ant-select-arrow:not(:last-child) {
  opacity: 1 !important;
}

.persona-dropdown.open .ant-select-arrow,
.speaker-dropdown.openSpeaker .ant-select-arrow {
  transform: rotate(180deg) !important;
  right: 10% !important;
}
@media only screen and (max-width: 1770px) {
  .edidpersona-main-container {
    .editpersona-top-div {
      max-width: 1050px !important;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .edidpersona-main-container {
    .editpersona-top-div {
      max-width: 900px !important;
      .edit-persona-card {
        .upload-img-div {
          img {
            width: 69px;
            height: 75px;
          }
          button {
            width: 143px;
          }
        }

        .modal-input,
        .persona-dropdown,
        .speaker-dropdown,
        .card-header,
        .stability-div {
          width: 255px !important;
        }

        .buttons {
          button {
            width: 109px;
          }
        }
      }
    }
  }
  .ant-slider-horizontal {
    width: 151px !important;
  }
}
@media only screen and (max-width: 1270px) {
  .edidpersona-main-container {
    .editpersona-top-div {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  .ant-slider-horizontal {
    width: 151px !important;
  }
}
@media only screen and (max-width: 700px) {
  .edidpersona-main-container {
    width: 100% !important;
    .editpersona-top-div {
      grid-template-columns: repeat(1, 1fr) !important;
      .edit-persona-card {
        width: 100% !important;
        max-width: 100% !important;
        .modal-input,
        .persona-dropdown,
        .speaker-dropdown,
        .card-header,
        .stability-div {
          width: 100% !important;
        }
      }
    }
    .editpersona-bottom-div {
      align-items: center;
      flex-direction: column;
    }
  }
  .ant-slider-horizontal {
    width: 201px !important;
  }
}

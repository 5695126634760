.ant-modal-footer {
  display: none;
}
.ant-modal-close {
  color: white !important;
}
.ant-modal-content {
  border-radius: 24px !important;
  background: var(--Dark, #0e0026) !important;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(16px) !important;
  padding: 24px !important;
  color: #fff !important;
}
.ant-modal-wrap {
  opacity: 0.99;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
}
.uplaodDoc-modal {
  .modal-content {
    gap: 24px;
    height: fit-content;
    padding-bottom: 44px;
    border: none !important;

    h3 {
      margin-top: 44px;
    }
    textarea {
      height: 126px;
    }
    button {
      width: 289px;
    }
    .bottom-progress-div {
      .partition-line {
        margin: auto;
        margin-bottom: 36px;
        margin-top: 30px;
        width: 215px;
        height: 1px;
        border-radius: 500px;
        background: linear-gradient(
          90deg,
          rgba(155, 40, 168, 0) 0%,
          #9b28a8 50%,
          rgba(155, 40, 168, 0) 100%
        );
      }
      .progress-main {
        margin-bottom: 36px;
        .upload-progress {
          gap: 10px;
          .right-div {
            .ant-progress-bg {
              background: #9b28a8 !important;
              height: 9px !important;
            }
            .ant-progress-inner {
              width: 255.375px !important;
              background: #d9d9d9 !important;
            }
            .ant-progress-text {
              display: none;
            }
          }
        }
        .percent {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.progress-modal {
  .modal-content {
    height: 359px;
    gap: 42px;
    button {
      width: 165px;
    }
  }
}
.customvalue-modal {
  .modal-content {
    gap: 36px;
    height: 359px;
    
    button {
      width: 165px;
    }
    .diverse-checkbox{
      // background: red;
    max-width: 358px;
    width: 100%;
    }
  }
}
.modal-content {
  background: transparent !important;
  z-index: 99;
}
.btn-disabled {
  opacity: 0.5;
  cursor: default !important;
}
.fileName {
  max-width: 255px;
}
.ant-tooltip-inner {
  background-color: white !important;
  color: black !important;
  border: 1px solid #d1d5db;
}

.ant-tooltip-arrow {
  border-color: white !important;
}
.error-message {
  color: red;
  font-size: 14px;
}
@media only screen and (max-width: 1260px) {
  .del-modal {
    width: 48% !important;
  }
  .customvalue-modal{
    .modal-content{
      gap: 20px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .uplaodDoc-modal,
  .progress-modal,
  .customvalue-modal,
  .dropdownInput-modal,
  .del-modal {
    width: 90% !important;
  }
  .customvalue-modal {
    .modal-content {
      input {
        // width: 295px !important;
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
}


// for choose topic model 
.topiccategory{
overflow-y: scroll;
width: 100%;
height: 300px;
margin-top: 30px;


  
 .cat-wrapper{
  transition: all .3s linear;
  padding: 15px;
  border-radius: 6px;
  margin-right: 6px;
  
 }
  h2{
    font-size: 20px;
    font-weight: 500;
    
  }
  ul{
    list-style-type: disc;
    padding: 20px;
    li{
      line-height: 130%;
      &:hover{
        background: #9b28a849;
        cursor: pointer;
        padding: 4px 0;
       
      }
    }
  }
 
 

}
.topics{
  padding-bottom: 2px!important;
  gap: 2px!important;
  h1{
    font-size: 30px;
    text-align: center;
  }
  button{
    margin-top: 10px;
    margin-left: auto!important;
    border-radius: 10px;
    background: var(--2-color-grad, linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%));
    color:white;
    font-size: 14px;
    font-weight: 700;
    width: 60px;
    height: 30px;
  }
}
.dash-pink-btn {
  border-radius: 10px;
  background: var(
    --2-color-grad,
    linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%)
  );
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  gap: 6px;
  height: 49px;
}
.trasnparent-btn {
  border-radius: 10px;
  border: 1px solid var(--2-color-grad, #9208f3);
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
}
.custom-button {
  width: 295px;
  height: 49px;
  background: #1e1e1e !important;
  border-radius: 10px;
  background: #1e1e1e;
  color: var(--Light-Grey, #959595);
  font-size: 15.777px;
  font-weight: 500;
  padding: 16px 21px;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.upload-button{
  border: none !important;
  height: 120px !important;
  width: 280px !important;
}
.dashnavbar-container {
  padding: 0px 42px;
  justify-content: space-between;

  .nav-search {
    position: relative !important;
    border-radius: 18px;
    background: #1e1e1e;
    width: 550px;
    padding: 16px 32px;
    height: 56px;
    gap: 17px;
    input,
    input::placeholder {
      width: 100%;
      background: transparent;
      border: none;
      color: #fff;
      font-size: 15.777px;
    }
    &::before {
      content: "";
      position: absolute;
      border-radius: 46.096px;
      opacity: 0.37;
      background: #940ee6;
      filter: blur(15.382046699523926px);
      max-width: 531px;
      width: 100%;
      height: 44px;
      z-index: -1;
      top: 25%;
      right: 2%;
    }
  }
  .profile-div {
    padding: 9px 31px 7px 8px;
    gap: 10px;
    border-radius: 18px;
    background: linear-gradient(91deg, #0e0026 0.86%, #9208f3 117.36%);
    img {
      width: 40.152px;
      height: 40.152px;
      border-radius: 50%;
    }
  }
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
@media only screen and (max-width: 1200px) {
  .dashnavbar-container {
    .nav-search {
      width: 420px;

      &::before {
        content: "";

        max-width: 481px;
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  .dashnavbar-container {
    padding: 0px 0px;
    width: 100%;
    gap: 20px;
    .menu-icon {
      width: 20px;
      height: 25px;
      display: block !important;
    }
    .nav-search {
      padding: 10px 20px;
      height: 50px;
    }
    .profile-div {
      padding: 9px 8px;
      p {
        display: none;
      }
    }
  }
}

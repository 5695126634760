.dropdownInput-modal {
  .ant-modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    gap: 34px;
    padding: 50px 20px;
    border: none !important;
    max-width: 295px;
    width: 100%;
    align-items: center;
    button {
      &:first-child {
        &:hover {
          background: var(
            --2-color-grad,
            linear-gradient(133deg, #9208f3 2.91%, #9b28a8 100%)
          ) !important;
        }
      }
      &:hover {
        color: #fff !important;
      }
      &:last-child {
        width: 295px;
      }
    }
    .selected {
      border: 2px solid #9208f3;
      color: #fff;
    }
  }
}

.ant-select {
  border-radius: 10px !important;
  background: #1e1e1e !important;
  height: 56px !important;
  color: var(--Light-Grey, #959595) !important;
}
:where(.css-17seli4).ant-select:hover .ant-select-arrow:not(:last-child) {
  opacity: 1 !important;
}
.ant-select-selection-search {
  &:active,
  &:focus {
    .ant-select-arrow {
      transform: rotate(180deg) !important;
      transition: transform 0.3s ease !important;
      // color: red !important;
    }
  }
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 16px 21px !important;
  color: var(--Light-Grey, #959595) !important;
  font-size: 15.777px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.ant-select-arrow {
  user-select: contain !important;
  color: #959595 !important;
  padding-right: 20px;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
  color: var(--Light-Grey, #959595) !important;
  font-size: 15.777px !important;
  font-weight: 500 !important;
}
.ant-select-dropdown,
.ant-select-dropdown-hidden,
.ant-select-dropdown-placement-bottomLeft {
  background-color: transparent !important;
  padding: 0px !important;
}
.ant-select-item {
  border-radius: 10px !important;
  background: #1e1e1e !important;
  padding: 16px 21px !important;
  height: 56px !important;
  color: var(--Light-Grey, #959595) !important;
  font-size: 15.777px !important;
  font-weight: 500 !important;
  margin-bottom: 2px !important;
  transition: 1ll 0.2s ease;
  &:hover {
    color: white !important;
  }
}
.rc-virtual-list-holder {
  max-height: fit-content !important;
  div:first-child {
    height: 291px;
  }
}
.modal-drop {
  .ant-select-dropdown-hidden {
    display: block !important;
  }
}

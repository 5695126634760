@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arial:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700&display=swap");

.Arial-family {
  font-family: Arial;
}
button {
  cursor: pointer !important;
}
h1 {
  font-size: 100px;
  font-weight: 600 !important;
}
h2 {
  font-size: 64px;
}
h3 {
  font-size: 26px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 18px;
}
.text-extraLarge {
  font-size: 18px;
}
.text-large {
  font-size: 16px;
}
.text-medium {
  font-size: 14px;
}
.text-small {
  font-size: 12px;
}
.text-para {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
    text-align: center;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 17px;
  }
  .text-extraLarge {
    font-size: 15px;
  }
  .text-large {
    font-size: 14px;
  }

  .text-medium {
    font-size: 13px;
  }

  .text-small {
    font-size: 12px;
  }
  .text-para {
    font-size: 16px;
  }
}

:root {
  --main-color: #0e0026;
  --pink: #9b28a8;
  --purple: #9208f3;
  --gray: #77798f;
  --cream: #e9deff;
  --white: #ffffff;
  --light-white: #fcfcfc;
  --light-grey: #959595;
  --red: #ff0000;
  --dark-purple: #12001a;
}

// color classes
.text-dark-purple{
  background-color: #74587e;
}
.text-red {
  color: var(--red);
}
.text-main {
  color: var(--main-color);
}
.text-pink {
  color: var(--pink);
}
.text-purple {
  color: var(--purple);
}
.text-gray {
  color: var(--gray);
}
.text-lightgray {
  color: var(--light-grey);
}
.text-white {
  color: var(--white);
}
.text-lightwhite {
  color: var(--light-white);
}
.text-cream {
  color: var(--cream);
}
.text-gradient {
  background-image: linear-gradient(90deg, #9208f3 6.5%, #9b28a8 94.5%);
}

.del-modal {
  .ant-modal-content {
    border-radius: 45.86px !important;
    border: 1px solid #a5a5a5 !important;
    background: #fff !important;
    padding: 28px !important;
    color: #000000 !important;
  }
  .ant-modal-close {
    color: #000000 !important;
    margin-right: 10px;
    &:hover {
      color: #f00 !important;
      background: none;
    }
  }
  .modal-content {
    border: none !important;
    height: 328px;
    gap: 34px;
    button {
      border-radius: 6px;
      width: 110px;
      height: 45px;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 600;
      &:first-child {
        color: #fff;
        background: #f00;
      }
      &:last-child {
        color: #f00;
        border: 1px solid #f00;
        background: #fff;
      }
    }
  }
}

.guideWrapper {
  padding: 20px;
  position: relative;
 // overflow: hidden;

  h2 {
    color: var(--blue-blue-000, #fff);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
    margin-bottom: 120px;
  }
  .guideGlow{
    position: absolute;
    top: -250px;
    right: 0;
    z-index: -1;
  }

  .guidenStoriesContainer {
    max-width: 1280px;
    height: 547px;
    margin: 0 auto;
    border-radius: 32px;
    overflow: hidden;
    // border: 1px solid purple;
    // background-color: rgb(122, 101, 141);
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

// .slick-track {
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: center;
//   }

.prev-btn {
  top: 450px;
  left: 10%;
  position: absolute;
  width: 62.505px;
  height: 62.505px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 18px;
  border-radius: 50px;
  outline: none;
}
.next-btn {
  top: 450px;
  right: 10%;
  position: absolute;
  width: 62.505px;
  height: 62.505px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 18px;
  border-radius: 50px;
  transform: rotate(180deg);
  outline: none;
}

// .slick-arrow.slick-prev {
//   position: absolute;
//   border: 2px solid #6d43ff;
//   background-color: aqua;
//   width: 50px;
//   height: 50px;
//   border-radius: 50px;
//   //top: 540px;
//   // left: 45.5%;
//   font-size: 20px !important;
//   z-index: 3;
//   &::before {
//     content: url("../../assets/images/home/leftAr.png");
//     position: absolute;
//     top: 8px;
//     left: 5px;
//     // color: var(--white);
//     opacity: 1;
//     transform: rotate(360deg);
//     //filter: brightness(0) invert(1);
//   }
// }
@media screen and (max-width: 1800px) {
  .guideWrapper .guidenStoriesContainer {
    max-width: 1071px;
  }
}

@media screen and (max-width: 1520px) {
  .guideWrapper {
    h2 {
      font-size: 45px;
    }
    .guidenStoriesContainer {
      max-width: 871px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .guideWrapper {
    .prev-btn {
      top: 450px;
      left: 5%;
      position: absolute;
      width: 50.505px;
      height: 50.505px;
    }

    .next-btn {
      top: 450px;
      right: 5%;
      position: absolute;
      width: 50.505px;
      height: 50.505px;
    }
    .guidenStoriesContainer {
    }
  }
}
@media screen and (max-width: 1100px) {
  .guideWrapper .guidenStoriesContainer {
    max-width: 789px;
  }
}

@media screen and (max-width: 600px) {
  .guideWrapper {
    // border: 1px solid red;
padding: 10px;
    h2 {
      font-size: 30px;
      margin-bottom: 0px;
    }
    .prev-btn {
      top: 63%;
      left: 5%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 22.505px;
      height: 22.505px;
      display: none;
    }
    .next-btn {
      top: 63%;
      right: 0%;
      transform: translate(-50%, -50%) rotate(180deg);
      position: absolute;
      width: 22.505px;
      height: 22.505px;
      display: none;
    }
    .guidenStoriesContainer{
      height: auto;
    }
  }
}
// @media screen and (max-width: 600px) {
//   .guideWrapper {
//     padding-bottom: 120px;

//     .next-btn {
//       // top: 411px;
//       top: 370px;
//       right: 35%;
//       position: absolute;
//       width: 45.505px;
//       height: 45.505px;
//     }

//     .prev-btn {
//       top: 370px;
//       left: 35%;
//       position: absolute;
//       width: 45.505px;
//       height: 45.505px;
//     }
//     h2 {
//       font-size: 30px;
//       margin-bottom: 0px;
//     }
//   }
// }

// @media screen and (max-width: 440px) {
//   .guideWrapper {
//     padding-bottom: 150px;
//     .next-btn {
//       top: 429px;
//       width: 40.505px;
//       height: 40.505px;
//       right: 30%;
//     }

//     .prev-btn {
//       top: 429px;
//       width: 40.505px;
//       height: 40.505px;
//       left: 30%;
//     }
//   }
// }
